<template>
  <core-box
    :is-container="isViewportDesktop"
    :margin="['8 0 0', '0']"
    :padding="['4 0 0', '6']"
  >
    <core-text
      type="heading"
      size="small"
      align="center"
    >
      Pagamento via {{ paymentMethodText }}
    </core-text>

    <core-box
      margin="1 0 1"
      :padding="['0 4', '0']"
      align-items="center"
    >
      <img
        class="qrcode"
        :src="code"
      >
    </core-box>
    <core-box
      class="action-buttons"
      align-items="center"
      justify-content="center"
      :direction="['column','row']"
      :margin="['4 0 2','4']"
      :width="['12','6']"
    >
      <a-button
        target="_blank"
        type="button"
        color="main"
        size="medium"
        is-block
        @click="addToClipboard()"
      >
        <template v-slot:icon>
          <svg
            width="12"
            height="12"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H12C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6V12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H6C5.46957 14 4.96086 13.7893 4.58579 13.4142C4.21071 13.0391 4 12.5304 4 12V6Z"
              fill="white"
            />
            <path
              d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V8C0 8.53043 0.210714 9.03914 0.585786 9.41421C0.960859 9.78929 1.46957 10 2 10V2H10C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0H2Z"
              fill="white"
            />
          </svg>
        </template>
        <template v-if="codeCopied">
          Código copiado
        </template>
        <template v-else>
          Copiar o código
        </template>
      </a-button>
    </core-box>
    <ul class="howto">
      <li
        v-for="tip, i in contextualTips"
        :key="i"
      >
        <core-box
          padding="4"
          direction="row"
          align-items="center"
        >
          <component
            :is="tip.icon"
            class="icon"
            height="30"
            width="20"
          />
          <core-text
            size="small"
            color="grey-medium"
            v-html="tip.text"
          />
        </core-box>
      </li>
    </ul>
    <core-box
      direction="row"
      align-items="center"
      justify-content="center"
      padding="2"
      margin="2 0"
      class="box-info"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3335 8.16667V9.16667H12.3335V8.16667H11.3335ZM4.66683 8.16667H3.66683V9.16667H4.66683V8.16667ZM9.00016 11.5C9.00016 10.9477 8.55245 10.5 8.00016 10.5C7.44788 10.5 7.00016 10.9477 7.00016 11.5H9.00016ZM7.00016 13.1667C7.00016 13.719 7.44788 14.1667 8.00016 14.1667C8.55245 14.1667 9.00016 13.719 9.00016 13.1667H7.00016ZM3.00016 9.16667H13.0002V7.16667H3.00016V9.16667ZM13.6668 9.83333V14.8333H15.6668V9.83333H13.6668ZM13.0002 15.5H3.00016V17.5H13.0002V15.5ZM2.3335 14.8333V9.83333H0.333496V14.8333H2.3335ZM3.00016 15.5C2.63197 15.5 2.3335 15.2015 2.3335 14.8333H0.333496C0.333496 16.3061 1.5274 17.5 3.00016 17.5V15.5ZM13.6668 14.8333C13.6668 15.2015 13.3684 15.5 13.0002 15.5V17.5C14.4729 17.5 15.6668 16.3061 15.6668 14.8333H13.6668ZM13.0002 9.16667C13.3684 9.16667 13.6668 9.46514 13.6668 9.83333H15.6668C15.6668 8.36057 14.4729 7.16667 13.0002 7.16667V9.16667ZM3.00016 7.16667C1.5274 7.16667 0.333496 8.36057 0.333496 9.83333H2.3335C2.3335 9.46514 2.63197 9.16667 3.00016 9.16667V7.16667ZM10.3335 4.83333V8.16667H12.3335V4.83333H10.3335ZM11.3335 7.16667H4.66683V9.16667H11.3335V7.16667ZM5.66683 8.16667V4.83333H3.66683V8.16667H5.66683ZM8.00016 2.5C9.28883 2.5 10.3335 3.54467 10.3335 4.83333H12.3335C12.3335 2.4401 10.3934 0.5 8.00016 0.5V2.5ZM8.00016 0.5C5.60693 0.5 3.66683 2.4401 3.66683 4.83333H5.66683C5.66683 3.54467 6.7115 2.5 8.00016 2.5V0.5ZM7.00016 11.5V13.1667H9.00016V11.5H7.00016Z"
          fill="#9CABC2"
        />
      </svg>
      <core-text
        size="small"
        color="grey-medium"
      >
        Criptografia de ponta a ponta para garantir que suas informações bancárias estejam protegidas.
      </core-text>
    </core-box>
  </core-box>
</template>

<script>
import { mapGetters } from 'vuex';
import gql from 'graphql-tag';

import CoreBox from '@wedy/legacy-components/core/core-box.vue';
import CoreText from '@wedy/legacy-components/core/core-text.vue';
import viewportly from '@wedy/legacy-components/mixins/viewportly';
import AButton from '@wedy/legacy-components/atoms/a-button.vue';
import CopySvg from '@wedy/legacy-assets/images/icons/copy.svg';
import BarCodeSvg from '@wedy/legacy-assets/images/wedding-site/checkout/bar-code.svg';
import CameraSvg from '@wedy/legacy-assets/images/wedding-site/checkout/camera.svg';
import SuccessSvg from '@wedy/legacy-assets/images/wedding-site/checkout/success.svg';
import PixSvg from '@wedy/legacy-assets/images/wedding-site/checkout/pix.svg';

const TIPS = {
  pix: [
    {
      icon: PixSvg,
      text: 'Abra o <strong>aplicativo do seu banco</strong> e entre no ambiente PIX',
    },
    {
      icon: CameraSvg,
      text: 'Escolha a opção <strong>pagar com QR code</strong> e aponte a câmera para o código acima',
    },
    {
      icon: SuccessSvg,
      text: 'Confirme as informações e <strong>finalize o pagamento</strong>.',
    },
  ],
};

export default {
  name: 'MPreApproval',
  components: {
    AButton,
    CoreBox,
    CoreText,
    CopySvg,
    BarCodeSvg,
    CameraSvg,
    SuccessSvg,
  },
  mixins: [
    viewportly,
  ],
  data() {
    return {
      codeCopied: false,
      code: undefined,
      transactionStatus: 'pending',
    };
  },
  computed: {
    ...mapGetters('checkout', ['checkout']),
    ...mapGetters('wedding', ['wedding']),
    contextualTips() {
      return TIPS[this.paymentMethod];
    },
    paymentMethod() {
      const { paymentMethod } = this.checkout.checkoutData;
      return paymentMethod;
    },
    paymentMethodText() {
      return {
        pix: 'Pix',
      }[this.paymentMethod];
    },
    plainCode() {
      const { result } = this.checkout;
      return result.pix_qrcode;
    },
  },
  apollo: {
    transactionStatus: {
      query: gql`query transactionStatus($identifier: String!) {
        transactionStatus(identifier: $identifier)
      }`,
      variables() {
        return {
          identifier: this.checkout.result.identifier,
        };
      },
      fetchPolicy: 'no-cache',
      pollInterval: 5000,
    },
  },
  watch: {
    transactionStatus(transactionStatus) {
      this.$emit('statusChanged', transactionStatus);
    },
  },
  created() {
    this.buildVisualCode();
  },
  methods: {
    async buildVisualCode() {
      const QRCode = require('qrcode');
      this.code = await QRCode.toDataURL(this.plainCode);
    },
    addToClipboard() {
      const fakeInput = document.createElement('input');
      document.body.appendChild(fakeInput);
      fakeInput.setAttribute('value', this.plainCode);
      fakeInput.select();
      document.execCommand('copy');
      document.body.removeChild(fakeInput);

      this.codeCopied = true;
      setTimeout(() => { this.codeCopied = false; }, 1500);
    },

  },
};
</script>

<style lang="stylus" scoped>
  .howto
    li
      .icon
        width 20px
        flex-shrink 0
        margin-right 10px
    li+li { border-top: 1px solid var(--grey-xlight) }
  .box-info
    background #F8F8F8
    border-radius 8px
    svg
      flex-shrink 0
      margin-right 10px

  .action-buttons
    gap 10px
    svg
      fill #000

  .qrcode
    max-height 180px
</style>
