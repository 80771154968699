<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div class="modal-card confirm-refuse">
      <div
        class="modal-close"
        @click="closePickCard"
      >
        <close-svg/>
      </div>
      <div class="modal__container">
        <div class="mockup__image show-md">
          <a-avatar
            class="mockup__couple-image"
            :width="{ default: '32px' }"
            :avatar-name="coupleName"
            :avatar-image="coupleImage"
          />
          <div>
            <img
              :src="instanceImages.wedding_site.cards_confirm_refuse"
              class="mockup"
            >
          </div>
        </div>
        <div class="info__container">
          <h1>
            Vamos sentir falta da sua lembrança!
          </h1>
          <div class="image__subtitle">
            Após o evento, todos os cartões com mensagens dos amigos e
            familiares serão entregues para {{ coupleName }}.
          </div>
          <div
            v-if="discountStatus"
            class="countdown"
          >
            <p>Ganhe 30% de desconto</p>
            <ATimer :seconds="90"/>
          </div>
          <div class="action__buttons">
            <div class="button-container">
              <a-legacy-button
                class="button"
                stacked
                lg
                @click.native="goBackToCards"
              >
                Quero participar!
              </a-legacy-button>
            </div>
          </div>
        </div>
        <div class="mockup__image hide-md">
          <a-avatar
            class="mockup__couple-image"
            :width="{ default: '44px' }"
            :avatar-name="coupleName"
            :avatar-image="coupleImage"
          />
          <img :src="instanceImages.wedding_site.cards_confirm_refuse">
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex';
import AAvatar from '@wedy/legacy-components/atoms/a-avatar.vue';
import ALegacyButton from '@wedy/legacy-components/atoms/a-legacy-button.vue';
import CloseSvg from '@wedy/legacy-assets/images/icons/close.svg';
import ATimer from '#/views/checkout/a-timer.vue';

export default {
  name: 'OPickCard',
  components: {
    AAvatar,
    ALegacyButton,
    CloseSvg,
    ATimer,
  },
  props: {
    coupleName: String,
    coupleImage: String,
  },
  computed: {
    ...mapGetters('checkout', ['discountStatus']),
  },
  mounted() {
    this.trackingCheckoutRemoveCard();
  },
  created() {
    this.addDiscountStatus();
    this.addCardDiscount(30);
    window.localStorage.setItem('discount', true);
  },
  methods: {
    ...mapMutations('checkout', ['addCardDiscount', 'addDiscountStatus']),
    goBackToCards() {
      this.$emit('backTCards');
      this.trackingCheckoutGiveUpRemoving();
    },
    closePickCard() {
      this.trackingCheckoutAcceptRemoving();
      this.$emit('closePickCard');
    },
  },
};
</script>
<style lang="stylus" scoped>

.confirm-refuse {
  height: 100vh !important;

  @media (max-width: md) {
    overflow: auto !important;
  }

  &.modal-card {
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    z-index: 20;
    top: 0;
    left: 0;
    background: #ffffff;

    @media (min-width: md) {
      position: relative;
      width: 900px;
      max-height: 600px;
      border-radius: 10px;
      padding: 2rem !important;
    }

    @media (max-width: md) {
      padding: 0 1.5rem !important;
      padding-bottom: 1.2rem !important;
      justify-content: space-between;
    }

    .modal-close {
      cursor: pointer;
      padding: 50px 0 10px 0;
      margin-left: auto;

      @media (min-width: md) {
        position: absolute;
        top: -12px;
        right: -12px;
        background: #eaeaea;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding 0;
      }

      &:hover {
        background: #f5f5f5;
      }

      svg {
        fill: #707070;
        width: 18px;
        // height: 12px;

        @media (min-width: md) {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .modal__container {
    display: flex;
    align-items: center;

    @media (max-width: md) {
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    .mockup__image {
      position: relative;
      display: flex;
      align-items: center;
      width: 435px;

      @media (max-width: md) {
        width: inherit;
        max-width: 300px;
      }

      .mockup__couple-image {
        position: absolute;
        top: 12px;
        left: 12px;
      }

      .mockup {
        border-radius: 10px;
        margin: auto;
        height: 100%;
        width: 100%;

        @media (max-height: 700px) {
          max-height: 300px;
          width: auto;
        }
      }
    }

    .info__container {
      display: flex;
      flex-direction: column;
      color: #141414;
      text-align: center;

      @media (min-width: md) {
        margin: 0 50px 0 25px;
        text-align: left;
      }

      h1 {
        font-size: 28px;
        font-weight: 600;
        line-height: 1.21;
        margin: 0 0 1rem 0;

        @media (max-width: md) {
          font-size: 18px;
          margin-top: 25px;
          margin-bottom: .7rem;
        }
      }

      .image__subtitle {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.1;
        margin-bottom: 1rem;

        @media (max-width: md) {
          line-height: 1.3;
          font-size: 14px;
          margin-bottom: .5rem;
        }
      }
    }
  }

  .action__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: md) {
      margin-top: auto;
    }

    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      width: 100%;

      @media (min-width: md) {
        margin-top: 25px;
      }

      .discount-bubble {
        background-color: #65c0c4;
        position: relative;
        display: inline-block;
        padding: 8px 16px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        margin-bottom: 9px;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-top-color: #65c0c4;
          border-bottom: 0;
          border-left: 0;
          margin-left: -10px;
          margin-bottom: -8px;
        }
      }

      .button {
        width: 100%;
        padding: 1.2rem 3rem;
        margin: 0;
        background: var(--main-color);
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .reject {
      font-size: 14px;
      font-weight: 300;
      color: #ffffff;
      margin-top: 25px;
      display: block;
      cursor: pointer;

      @media (max-width: md) {
        margin-top: 15px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
  }

.countdown {
  display: flex;
  background-color: var(--main-color-cta);
  color: var(--grey-xlight);
  flex-direction: row;
  justify-content space-between;
  align-items center;
  font-size: 1.1rem;
  letter-spacing: 3px;
  padding: 15px 15px;
  width: 100%;
  border-radius: .35rem;
  max-width: 280px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  font-weight: bold;

  & p {
    font-size: .9rem;
    margin: 0px;
    letter-spacing: 0px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
    background-color: var(--main-color-cta);
    left: 20%;
    bottom: -10px;
    z-index: -1;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
