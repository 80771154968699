/* eslint-disable no-console */

import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import Notifications from 'vue-notification';
import router from './router';

Vue.use(Notifications, { router });

const endpointLink = new HttpLink({
  uri: `${window.appHostDomain}/graphql`,
});

Vue.use(Notifications);

const errorLink = onError(({ graphQLErrors, networkError, operation: { operationName } }) => {
  if (typeof operationName === 'string' && operationName.startsWith('authenticated')) {
    return;
  }

  if (graphQLErrors || networkError) {
    Vue.notify({
      group: 'main',
      title: 'Ops, algo deu errado!',
      text: 'Ops! Algo aconteceu de errado que não esperávamos.',
      type: 'error',
      duration: 5000,
    });
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        const output = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        console.error(output);
      });
    } else if (!networkError.statusCode === 401) {
      const output = `[Network error]: Message: ${networkError}`;
      console.error(output);
    }
  }
});

const links = [errorLink, endpointLink];
const link = ApolloLink.from(links);

export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
