import Vue from 'vue';
import VeeValidateMessagesBR from 'vee-validate/dist/locale/pt_BR';
import VeeValidate, { Validator } from 'vee-validate';

const dict = {
  custom: {
    guestPhone: {
      required: 'Opa, isso não pode ficar vazio',
      min: 'Número de telefone inválido',
    },
    guestName: {
      required: 'Opa, isso não pode ficar vazio',
      min: 'O campo deve conter ao menos 3 caracteres',
    },
    guestEmail: {
      required: 'Opa, isso não pode ficar vazio',
      email: 'Termine de digitar o seu e-mail',
    },
    mensagem: {
      required: 'Opa, isso não pode ficar vazio',
      max: 'O campo deve conter até 600 caracteres.',
    },
  },
};

Validator.localize('pt_BR', dict);
Validator.localize('pt_BR', VeeValidateMessagesBR);
Vue.use(VeeValidate, { locale: 'pt_BR' });
