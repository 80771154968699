<template>
  <div
    :style="{ width: imageSize, height: imageSize}"
    class="avatar-image__content"
  >
    <img
      v-if="avatarImage && !missingImage.includes(avatarImage)"
      :style="{ 'width': imageSize, 'height': imageSize, 'border': border}"
      :src="avatarImage"
      loading="lazy"
      class="avatar-image custom"
    >
    <a-avatar-name
      v-if="!avatarImage || missingImage.includes(avatarImage)"
      :name="avatarName || currentInstance.name"
      :size="imageSize.replace('px', '')"
      :style="{ width: imageSize, height: imageSize }"
      rounded
    />
    <span
      v-if="favored"
      class="badge"
      title="Recém-casados"
    >
      <star-svg/>
    </span>
  </div>
</template>

<script>
import StarSvg from '@wedy/legacy-assets/badges/star.svg';
import authentically from '../mixins/authentically';
import AAvatarName from './a-avatar-name.vue';

export default {
  name: 'AAvatar',
  components: { AAvatarName, StarSvg },
  mixins: [authentically],
  props: {
    width: Object,
    avatarName: { type: String },
    avatarImage: { type: String },
    favored: {
      type: Boolean,
      default: false,
    },
    border: String,
  },
  data() {
    return {
      imageSize: '',
      missingImage: ['/assets/thumb/missing.png', '/assets/original/missing.png'],
    };
  },
  computed: {
    isMissingImage() {
      return this.avatarImage === '/assets/original/missing.png' || this.avatarImage === '/assets/thumb/missing.png';
    },
  },
  mounted() {
    const sizes = (this.width.dimensions || []).filter((dimensions) => window.matchMedia(`(min-width: ${dimensions.mq})`).matches);
    const hasSize = sizes.length > 0 && sizes !== 'undefined';
    this.imageSize = hasSize ? sizes[sizes.length - 1].size : this.width.default;
  },
};
</script>

<style lang="stylus" scoped>
.avatar-image__content
  position relative

  .avatar-image
    object-fit cover

    &.custom
      border-radius 50%

  .badge
    position absolute
    background #ffffff
    display inline-flex
    justify-content center
    align-items center
    border-radius 50%
    bottom 0
    right 0
    box-shadow 0 1px 9px 0 rgba(0,0,0,0.3)
    width 40%

    svg
      width 100%
      margin 20%
</style>
