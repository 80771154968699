// eslint-disable-next-line import/no-extraneous-dependencies
import 'tailwindcss/tailwind.css';

import './pixels';

import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueMask from 'v-mask';
import VueMoney from 'v-money';
// eslint-disable-next-line
import VueImg from 'v-img';
import Resource from 'vue-resource';
import VueApollo from 'vue-apollo';
import Notifications from 'vue-notification';

import apolloClient from './apollo';
import router from './router';
import store from './store';
import WedyApp from './wedy-wedding-site.vue';

import TrackingManager from './tracking-manager';

import { platformServices, universalServices } from './services';
import setupPlatformInstance from './setups/platform-instance';
import setupServices from './setups/services';

import '@wedy/legacy-assets/stylus/base.styl';

import '@wedy/legacy-assets/stylus/common.styl';
import '@wedy/legacy-assets/stylus/grid.styl';
import '@wedy/legacy-assets/stylus/text.styl';
import { PLATFORM_INSTANCE } from './constants';

Vue.use(VueMeta);
Vue.use(Resource);
Vue.use(VueMask);
Vue.use(VueMoney);
Vue.use(VueImg);
Vue.use(Notifications);
Vue.use(TrackingManager);

Vue.router = router;
Vue.config.productionTip = false;
Vue.http.options.root = window.appHostDomain;

require('./validator');
require('./global-mixin');

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

const render = (h) => h(WedyApp);

Vue.config.errorHandler = (err) => {
  Vue.notify({
    group: 'main',
    title: 'Ops, algo deu errado!',
    text: 'Ops... Aconteceu um problema! Tente novamente mais tarde.',
    duration: 5000,
    type: 'error',
  });
  console.error(err);
  if (process.env.NODE_ENV !== 'production') throw (err);
};

setupServices(universalServices);

setupServices(platformServices, PLATFORM_INSTANCE);
setupPlatformInstance(PLATFORM_INSTANCE);

const WEDY_SLUG = 'wedy';

Vue.mixin({
  computed: {
    isWedy() {
      return this.currentInstance.slug === WEDY_SLUG;
    },
    currentInstance() {
      return PLATFORM_INSTANCE;
    },
    instanceImages() {
      return PLATFORM_INSTANCE.publicContent.appearance.images;
    },
  },
});

new Vue({
  router,
  store,
  apolloProvider,
  render,
}).$mount('#wedy-wedding-site');
