export default function getScrollParent(el) {
  if (!(el instanceof HTMLElement) || typeof window.getComputedStyle !== 'function') {
    return null;
  }

  if (el.scrollHeight >= el.clientHeight && !/^(visible|hidden)/.test(window.getComputedStyle(el).overflowY || 'visible')) {
    return el;
  }

  return getScrollParent(el.parentElement) || document.body;
}
