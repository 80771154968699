var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-input",class:{
    'valid': _vm.value != '' && _vm.value != undefined && !_vm.error,
    'invalid': !!_vm.error,
    'noshadow': _vm.noshadow,
    'bordered': _vm.bordered,
    'readonly': _vm.readonly,
    'borde-bottom': _vm.bordeBottom,
  }},[_c('div',{staticClass:"box-input__field"},[_c('div',{staticClass:"field__container"},[_vm._t("default"),(_vm.prefix && _vm.prefix.length)?_c('span',{staticClass:"prefix"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._e(),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask ? _vm.mask : ''),expression:"mask ? mask : ''"}],ref:"input",class:{
          'number': _vm.type == 'number',
          'text-centered': _vm.textCenter,
        },attrs:{"data-hj-whitelist":"","type":_vm.type === 'slug' ? 'text' : _vm.type,"placeholder":_vm.placeholder,"required":_vm.required,"tabindex":_vm.tabindex,"name":_vm.name,"readonly":_vm.readonly,"inputmode":_vm.inputmode,"pattern":_vm.pattern,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.innerValue},on:{"keydown":function($event){return _vm.$emit('keydown', $event)},"input":_vm.handleInput,"blur":_vm.handleBlur}}),(!_vm.hideVerified)?_c('verified-svg',{staticClass:"verified"}):_vm._e()],2)]),_c('transition',{attrs:{"name":"error-fade","mode":"out-in"}},[(!!_vm.error)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }