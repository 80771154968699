<template>
  <notifications
    :group="group"
    :position="currentPosition"
    :ignore-duplicates="ignoreDuplicates"
    :max="maxNotificationsNumber"
    :speed="500"
    width="320px"
  >
    <template
      slot="body"
      slot-scope="{ item }"
    >
      <a-notification-simple
        v-if="isSimpleType(item.type)"
        :type="item.type"
        :title="item.title"
        :text="item.text"
      />
      <a-notification-help
        v-if="isHelpType(item.type)"
        :text="item.text"
      />
    </template>
  </notifications>
</template>

<script>
import viewportly from '../mixins/viewportly';
import ANotificationSimple from '../atoms/a-notification-simple.vue';
import ANotificationHelp from '../atoms/a-notification-help.vue';

const SUCCESS_TYPE = 'success';
const WARNING_TYPE = 'warn';
const ERROR_TYPE = 'error';
const HELP_TYPE = 'help';

const MAIN_GROUP = 'main';
const HELP_GROUP = 'help';

const MAX_NOTIFICATIONS = 5;

export default {
  name: 'MNotificationsContainer',
  components: {
    ANotificationSimple,
    ANotificationHelp,
  },
  mixins: [viewportly],
  props: {
    group: {
      type: String,
      default: MAIN_GROUP,
      validator: (value) => [MAIN_GROUP, HELP_GROUP].includes(value),
    },
    position: {
      type: [String, Array],
      default: () => ['top center', 'top right'],
    },
    ignoreDuplicates: {
      type: Boolean,
      default: false,
    },
    maxNotifications: {
      type: [String, Number],
      default: MAX_NOTIFICATIONS,
    },
  },
  computed: {
    currentPosition() {
      const normalizedValue = Array.isArray(this.position) ? this.position : [this.position];
      const [mobile, desktop] = normalizedValue;
      if (!desktop) {
        return mobile;
      }
      return this.isViewportMobile ? mobile : desktop;
    },
    maxNotificationsNumber() {
      return Number(this.maxNotifications) || MAX_NOTIFICATIONS;
    },
  },
  methods: {
    isSimpleType(type) {
      return [SUCCESS_TYPE, WARNING_TYPE, ERROR_TYPE].includes(type);
    },
    isHelpType(type) {
      return type === HELP_TYPE;
    },
  },
};
</script>

<style lang="stylus">
.vue-notification-group
  padding 10px

  +for_down(md)
    padding 10px 0

  .vue-notification-wrapper
    margin 10px 0px
</style>
