<template>
  <component
    :is="normalizedTag"
    :class="classes"
  >
    <slot/>
  </component>
</template>

<script>
import {
  mergeResponsiveProps,
  normalizeResponsiveProp,
} from '../utils/responsive-prop';

const ALIGN_CENTER = 'center';
const ALIGN_JUSTIFY = 'justify';
const ALIGN_LEFT = 'left';
const ALIGN_RIGHT = 'right';

const COLOR_ALERT = 'alert';
const COLOR_DARK = 'dark';
const COLOR_GREY_LIGHT = 'grey-light';
const COLOR_GREY_MEDIUM = 'grey-medium';
const COLOR_LIGHT = 'light';
const COLOR_MAIN = 'main';
const COLOR_SUCCESS = 'success';

const OVERFLOW_CLIP = 'clip';
const OVERFLOW_ELLIPSIS = 'ellipsis';

const SIZE_LARGE = 'large';
const SIZE_REGULAR = 'regular';
const SIZE_SMALL = 'small';

const TYPE_BODY = 'body';
const TYPE_CTA = 'cta';
const TYPE_HEADING = 'heading';
const TYPE_HERO = 'hero';
const TYPE_HIGHLIGHT = 'highlight';
const TYPE_QUOTE = 'quote';

const WEIGHT_100 = '100';
const WEIGHT_300 = '300';
const WEIGHT_400 = '400';
const WEIGHT_500 = '500';
const WEIGHT_600 = '600';
const WEIGHT_700 = '700';
const WEIGHT_800 = '800';
const WEIGHT_900 = '900';

const MAPPING = {
  [TYPE_BODY]: {
    [SIZE_REGULAR]: {
      tag: 'p',
      weight: WEIGHT_400,
    },
    [SIZE_SMALL]: {
      tag: 'p',
      weight: WEIGHT_400,
    },
  },
  [TYPE_CTA]: {
    [SIZE_REGULAR]: {
      tag: 'span',
      weight: WEIGHT_600,
    },
    [SIZE_SMALL]: {
      tag: 'span',
      weight: WEIGHT_600,
    },
  },
  [TYPE_HEADING]: {
    [SIZE_LARGE]: {
      tag: 'h1',
      weight: WEIGHT_600,
    },
    [SIZE_REGULAR]: {
      tag: 'h2',
      weight: WEIGHT_600,
    },
    [SIZE_SMALL]: {
      tag: 'h3',
      weight: WEIGHT_600,
    },
  },
  [TYPE_HERO]: {
    [SIZE_LARGE]: {
      tag: 'h1',
      weight: WEIGHT_600,
    },
    [SIZE_SMALL]: {
      tag: 'h3',
      weight: WEIGHT_600,
    },
  },
  [TYPE_HIGHLIGHT]: {
    [SIZE_REGULAR]: {
      tag: 'span',
      weight: WEIGHT_500,
    },
    [SIZE_SMALL]: {
      tag: 'span',
      weight: WEIGHT_500,
    },
  },
  [TYPE_QUOTE]: {
    [SIZE_REGULAR]: {
      tag: 'p',
      weight: WEIGHT_400,
    },
    [SIZE_SMALL]: {
      tag: 'p',
      weight: WEIGHT_400,
    },
  },
};

export default {
  name: 'CoreText',
  inheritAttrs: false,
  props: {
    align: {
      type: String,
      default: ALIGN_LEFT,
      validator: (value) => [
        ALIGN_CENTER,
        ALIGN_JUSTIFY,
        ALIGN_LEFT,
        ALIGN_RIGHT,
      ].includes(value),
    },
    color: {
      type: String,
      default: COLOR_DARK,
      validator: (value) => [
        COLOR_ALERT,
        COLOR_DARK,
        COLOR_GREY_LIGHT,
        COLOR_GREY_MEDIUM,
        COLOR_LIGHT,
        COLOR_MAIN,
        COLOR_SUCCESS,
      ].includes(value),
    },
    overflow: {
      type: String,
      default: undefined,
      validator: (value) => [
        OVERFLOW_CLIP,
        OVERFLOW_ELLIPSIS,
      ].includes(value),
    },
    size: {
      type: [Array, String],
      default: SIZE_REGULAR,
    },
    type: {
      type: [Array, String],
      default: TYPE_BODY,
    },
    weight: {
      type: String,
      default: undefined,
      validator: (value) => [
        WEIGHT_100,
        WEIGHT_300,
        WEIGHT_400,
        WEIGHT_500,
        WEIGHT_600,
        WEIGHT_700,
        WEIGHT_800,
        WEIGHT_900,
      ].includes(value),
    },
    transform: {
      type: String,
      default: undefined,
      validator: (value) => ['uppercase', 'lowercase', 'capitalize'].includes(value),
    },
  },
  computed: {
    classes() {
      const normalizedSizeType = mergeResponsiveProps([
        normalizeResponsiveProp(
          this.size,
          (rawSize) => rawSize || undefined,
          false,
        ),
        normalizeResponsiveProp(
          this.type,
          (rawType) => rawType || undefined,
          false,
        ),
      ], (rawSize, rawType) => (
        `type-${rawType}-${rawSize}`
      ));

      return [
        'text',
        ...normalizedSizeType,
        this.transform && `transform-${this.transform}`,
        this.align && `align-${this.align}`,
        this.color && `color-${this.color}`,
        this.overflow && `overflow-${this.overflow}`,
        this.normalizedWeight && `weight-${this.normalizedWeight}`,
      ];
    },
    mapped() {
      return !Array.isArray(this.size) && !Array.isArray(this.type)
        ? (MAPPING[this.type] || {})[this.size] || {}
        : {};
    },
    normalizedTag() {
      return this.mapped.tag || 'div';
    },
    normalizedWeight() {
      return this.weight || this.mapped.weight || WEIGHT_400;
    },
  },
};
</script>

<style lang="stylus" scoped>
generate_type_size(suffix)
  &.type

    &-body

      &-regular{suffix}
        font-size 16px
        line-height 20px

      &-small{suffix}
        font-size 14px
        line-height 17px

    &-cta

      &-regular{suffix}
        font-size 18px
        line-height 22px

      &-small{suffix}
        font-size 16px
        line-height 20px

    &-heading

      &-large{suffix}
        font-size 30px
        line-height 37px

      &-regular{suffix}
        font-size 28px
        line-height 35px

      &-small{suffix}
        font-size 24px
        line-height 30px

    &-hero

      &-large{suffix}
        font-size 65px
        line-height 65px

      &-small{suffix}
        font-size 45px
        line-height 45px

    &-highlight

      &-regular{suffix}
        font-size 16px
        line-height 20px

      &-small{suffix}
        font-size 14px
        line-height 17px

    &-quote

      &-regular{suffix}
        font-size 16px
        line-height 28px

      &-small{suffix}
        font-size 14px
        line-height 25px

.text
  margin 0
  padding 0
  letter-spacing -0.02rem

  +for_down(sm)
    suffix = '-sm'
    generate_type_size(suffix)

  +for_between(sm, md)
    suffix = '-md'
    generate_type_size(suffix)

  +for_between(md, lg)
    suffix = '-lg'
    generate_type_size(suffix)

  +for_up(lg)
    suffix = '-xl'
    generate_type_size(suffix)

  &.transform

    &-uppercase
      text-transform uppercase

    &-lowercase
      text-transform lowercase

    &-capitalize
      text-transform capitalize

  &.align

    &-center
      text-align center

    &-justify
      text-align justify

    &-left
      text-align left

    &-right
      text-align right

  &.color

    &-alert
      color var(--alert-color)

    &-dark
      color var(--dark-color)

    &-grey-light
      color var(--grey-light)

    &-grey-medium
      color var(--grey-medium)

    &-light
      color var(--light-color)

    &-main
      color var(--main-color)

    &-success
      color var(--success-color)

  &.overflow

    &-clip
      overflow hidden
      text-overflow clip
      white-space nowrap

    &-ellipsis
      overflow hidden
      text-overflow ellipsis
      white-space nowrap

  &.weight

    &-100
      font-weight 100

    &-300
      font-weight 300

    &-400
      font-weight 400

    &-500
      font-weight 500

    &-600
      font-weight 600

    &-700
      font-weight 700

    &-800
      font-weight 800

    &-900
      font-weight 900
</style>
