<template>
  <span>
    <slot/>
  </span>
</template>

<script>
import balanceText from 'balance-text';

export default {
  name: 'ABalancetext',
  mounted() {
    balanceText(this.$el, { watch: true });
  },
  activated() {
    this.update();
  },
  deactivated() {
    this.update();
  },
  beforeDestroy() {
    balanceText(this.$el, { watch: false });
  },
  methods: {
    update() {
      this.$nextTick(() => {
        balanceText(this.$el);
      });
    },
  },
};
</script>
