<template>
  <div class="image-loading">
    <div
      :class="['image-load-inside', `position-${position}` ]"
    >
      <div :class="['circle',`color-${color}`,`size-${size}`]"/>
      <div :class="['circle',`color-${color}`,`size-${size}`]"/>
      <div :class="['circle',`color-${color}`,`size-${size}`]"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'APointLoader',
  props: {
    color: {
      type: String,
      default: 'light-grey',
      validator: (value) => ['dark-grey', 'light-grey'].includes(value),
    },
    size: {
      type: String,
      default: 'regular',
      validator: (value) => ['regular', 'small'].includes(value),
    },
    position: {
      type: String,
      default: 'none',
      validator: (value) => ['none', 'center'].includes(value),
    },
  },
};
</script>

<style lang="stylus" scoped>
.image-loading {
  z-index: 50;

  .image-load-inside{
    position: relative;
    bottom: 200%;

    &.position-center {
      display: flex;
      justify-content: center;
    }

  }

  .circle {
    display: inline-block;
    border-radius: 50%;

    &.color-light-grey {
      background-color: #cacaca;
    }

    &.color-dark-grey {
      background-color: #AAA;
    }

    &.size-regular {
      width: 16px;
      height: 16px;

      & + .circle {
        margin-left: 6px;
      }
    }

    &.size-small {
      width: 12px;
      height: 12px;

      & + .circle {
        margin-left: 4px;
      }
    }
  }

  .image-load-inside .circle:nth-last-child(1) {
    animation: loadingC .7s -200ms linear infinite;
  }

  .image-load-inside .circle:nth-last-child(2) {
    animation: loadingC .7s -100ms linear infinite;
  }

  .image-load-inside .circle:nth-last-child(3) {
    animation: loadingC .7s linear infinite;
  }

  @keyframes loadingC {
    0% {transform: translate(0,-40%);}
    50% {transform: translate(0,40%);}
    100% {transform: translate(0,-40%);}
  }
}
</style>
