<template>
  <div
    class="back-header"
    @click="goBack"
  >
    <arrow-left-svg/>
    Voltar
  </div>
</template>

<script>
import ArrowLeftSvg from '@wedy/legacy-assets/images/icons/arrow-left.svg';

export default {
  name: 'ABackHeader',
  components: {
    ArrowLeftSvg,
  },
  props: {
    backRoute: String,
  },
  methods: {
    goBack() {
      this.$emit('backHasBeenClicked');
    },
  },
};
</script>

<style lang="stylus" scoped>
.back-header {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  padding-left: 0.3rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.43;
  text-align: left;
  color: #808080;
  width: 100px;

  svg {
    width: 0.6rem;
    heigth: 1rem;
    fill: #808080;
    margin-right: 0.6rem;
  }
}
</style>
