<template>
  <div>
    <a-lottie
      v-if="!isFetching"
      :options="defaultOptions"
      :width="width"
      :height="height"
      @animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import ALottie from '../atoms/a-lottie.vue';

export default {
  name: 'MAnimation',
  components: {
    ALottie,
  },
  props: {
    name: {
      type: String,
      default: 'wallet',
    },
    jsonUrl: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    looping: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFetching: true,
      defaultOptions: {
        animationData: '',
        loop: true,
      },
      animationSpeed: 1,
    };
  },
  created() {
    this.loadFile(this.name).then((res) => {
      // eslint-disable-next-line
      this.defaultOptions.animationData = res;
      this.isFetching = false;
    });
    this.defaultOptions.loop = this.looping;
  },
  methods: {
    handleAnimation: function handleAnimation(animation) {
      this.animation = animation;
    },

    makeRequest(url) {
      const http = new XMLHttpRequest();
      http.open('GET', url, false);
      http.send();
      return http.responseText;
    },

    async loadFile(name) {
      if (this.jsonUrl) {
        return JSON.parse(this.makeRequest(this.jsonUrl));
      }
      // eslint-disable-next-line
      return require(`@wedy/legacy-assets/animations/${name}.json`);
    },
  },
};
</script>
