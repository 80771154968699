<template>
  <provider-wedding v-slot="{ isWeddingReady }">
    <template v-if="(isWeddingReady)">
      <router-view/>
    </template>
  </provider-wedding>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

import ProviderWedding from '#/providers/provider-wedding';

export default {
  name: 'VWeddingSite',
  components: {
    ProviderWedding,
  },
  computed: {
    ...mapState('wedding', [
      'wedding',
    ]),
    title() {
      const {
        coupleName,
        date,
      } = this.wedding || {};

      if (!coupleName) {
        return 'Carregando...';
      }

      return [
        coupleName,
        date && dayjs(date).format('DD/MM/YYYY'),
      ].filter(Boolean).join(' - ');
    },
    description() {
      const {
        name: platformName = 'Wedy',
      } = this.currentInstance;

      return `Site de casamento e lista de casamento \
        do casal ${this.title} na ${platformName}`;
    },
    ogImage() {
      const { ogImageUrl } = this.wedding || {};
      return ogImageUrl;
    },
  },
  metaInfo() {
    return {
      title: this.title,
      description: this.description,
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.title,
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.description,
        },
        this.ogImage && {
          vmid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
      ].filter(Boolean),
    };
  },
};
</script>
