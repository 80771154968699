<template>
  <div class="header">
    <div
      class="header-back-arrow"
      @click="pushRoute"
    >
      <arrow-left-svg
        :style="{fill: color}"
        class="back__arrow"
      />
    </div>
    <div
      :class="{
        'title__left': titleAlignment == 'left',
        'title__right': titleAlignment == 'right',
      }"
      class="header-text"
      @click="pushRoute"
    >
      <span
        :style="{'color': color, 'cursor': titleAlignment == 'right' ? 'pointer' : 'default'}"
        @click="titleAlignment == 'right' ? goLink() : null"
      >
        {{ text }}
      </span>
    </div>
    <div
      class="link-icon"
      @click="goLink"
    >
      <external-link-svg
        v-if="toProfile"
        :style="{fill: color}"
        class="link__svg"
      />
      <gear-svg
        v-if="toSettings"
        :style="{fill: color}"
        class="link__svg"
      />
    </div>
  </div>
</template>

<script>
import ArrowLeftSvg from '@wedy/legacy-assets/images/icons/arrow-left.svg';
import ExternalLinkSvg from '@wedy/legacy-assets/images/icons/external-link.svg';
import GearSvg from '@wedy/legacy-assets/images/icons/gear.svg';

export default {
  name: 'AHeader',
  components: {
    ArrowLeftSvg,
    ExternalLinkSvg,
    GearSvg,
  },
  props: {
    color: String,
    text: String,
    emitEvent: {
      type: Boolean,
      default: false,
    },
    backRoute: {
      type: String,
      default: '/settings',
    },
    titleAlignment: {
      type: String,
      default: 'center',
      validator(value) {
        return value === 'left' || value === 'center' || value === 'right';
      },
    },
    toProfile: {
      type: Boolean,
      default: false,
    },
    toSettings: {
      type: Boolean,
      default: false,
    },
    link: String,
    goBack: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goLink() {
      if (this.toProfile === true) {
        window.open(this.link, '_blank');
      } else {
        this.$router.push(this.link);
      }
    },
    pushRoute() {
      if (!this.emitEvent) { this.$router.push(`${this.backRoute}`); } else { this.$emit('backButtonClicked'); }
    },
  },
};
</script>

<style lang="stylus" scoped>
.header {
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 3px;
  justify-content: space-between;

  .header-back-arrow {
    cursor: pointer;

    .back__arrow {
      width: 0.6rem;
      fill: #141414;
      display: flex;
      align-items: center;
    }
  }

  .header-text {
    display: flex;

    &.title__left {
      flex-grow: 1;
      justify-content: flex-start;
      margin-left: .5rem;
    }

    &.title__right {
      flex-grow: 1;
      justify-content: flex-end;
      margin-right: .5rem;
    }
  }

  .link-icon {
    .link__svg {
      width: 1.2rem;
      fill: #141414;
      cursor: pointer;
    }
  }

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;
    color: #141414;
  }
}
</style>
