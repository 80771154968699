import adwords from './adwords';
import analytics from './analytics';
import facebook from './facebook';
import facebookPixel from './facebook-pixel';
import hotjar from './hotjar';

export const platformServices = [
  facebook,
  facebookPixel,
];

export const universalServices = [
  adwords,
  analytics,
  hotjar,
];
