var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"legacy-btn",class:{
    'legacy-btn-stacked': _vm.stacked,
    'legacy-btn-ghost': _vm.ghost,
    'legacy-btn-disabled': _vm.disabled,
    'legacy-btn-icon': _vm.icon,
    'legacy-btn-shadow': _vm.shadow,
    'legacy-btn-lg': _vm.lg,
    'legacy-btn-md': _vm.md },style:(("background-color: " + _vm.color)),attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":_vm.cliked}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }