<template>
  <button
    :type="type"
    :class="{
      'legacy-btn-stacked': stacked,
      'legacy-btn-ghost': ghost,
      'legacy-btn-disabled': disabled,
      'legacy-btn-icon': icon,
      'legacy-btn-shadow': shadow,
      'legacy-btn-lg': lg,
      'legacy-btn-md': md }"
    :disabled="disabled"
    :style="`background-color: ${color}`"
    class="legacy-btn"
    @click="cliked"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'ALegacyButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    stacked: Boolean,
    ghost: Boolean,
    icon: Boolean,
    disabled: Boolean,
    shadow: Boolean,
    lg: Boolean,
    md: Boolean,
    to: [String, Object],
    color: String,
  },
  methods: {
    cliked() {
      if (this.to) this.$router.push(this.to);
    },
  },
};
</script>

<style lang="stylus" scoped>
.legacy-btn {
  display: inline-block;
  white-space: nowrap;
  border-radius: .35rem;
  border-color: transparent;
  cursor: pointer;
  font-weight: 400;
  font-size: .875rem;
  transition: all .3s;
  color: #ffffff;
  padding: .75rem 1.2rem;
  -webkit-tap-highlight-color:transparent;

  &:hover {
    opacity: .8;
  }

  &.legacy-btn-stacked {
    display: block;
    width: 100%;

    & + .legacy-btn-stacked {
      margin-top: 1rem;
    }
  }

  &.legacy-btn-ghost {
    border: 1px solid #ffffff;
    background: transparent;

    &:hover {
      background-image: linear-gradient(
        206deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.29)
      );
    }
  }

  &.legacy-btn-disabled {
    opacity: .7;
    cursor: not-allowed;
  }

  &.legacy-btn-icon {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    padding-left: .8rem;
    padding-right: .8rem;

    svg {
      margin-right: .5rem;
    }
  }

  &.legacy-btn-shadow {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  }

  &.legacy-btn-lg {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  &.legacy-btn-md {
    padding-top: .9rem;
    padding-bottom: .9rem;
  }

  &:focus {
    outline: none;
  }
}
</style>
