/* eslint-disable no-param-reassign, no-undef */

const TrackingManager = {};

TrackingManager.install = function b(Vue) {
  const tryFunction = (execFunction) => {
    try { execFunction(); } catch (err) { console.error(err); }
  };

  Vue.mixin({
    methods: {
      trackingCheckoutStarted(websiteSlug) {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'GoodCheckout',
              eventLabel: 'Started',
            });
          },
          () => {
            _fbq('track', 'AddToCart', {
              content_type: 'product',
              content_ids: [websiteSlug],
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutPaymentStarted() {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'GoodCheckout',
              eventLabel: 'PaymentStarted',
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutPaymentDone(websiteSlug) {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'GoodCheckout',
              eventLabel: 'PaymentDone',
            });
          },
          () => {
            _fbq('track', 'Purchase', {
              content_type: 'product',
              content_ids: [websiteSlug],
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutIntentionToRemoveCardByCloseSvg() {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'RemoveGreetingCard',
              eventLabel: 'IntentionToRemoveCardByCloseSvg',
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutCardRemoveByModal() {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'RemoveGreetingCard',
              eventLabel: 'CardRemoveByModal',
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutRemoveCard() {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'RemoveGreetingCard',
              eventLabel: 'RemoveCard',
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutAcceptRemoving() {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'RemoveGreetingCard',
              eventLabel: 'AcceptRemoving',
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutGiveUpRemoving() {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'RemoveGreetingCard',
              eventLabel: 'GiveUpRemoving',
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutOpenedPickMessageModal() {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'SelectMessage',
              eventLabel: 'OpenedPickMessageModal',
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
      trackingCheckoutChoseMessageFromModal() {
        const pixels = [
          () => {
            Vue.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'SelectMessage',
              eventLabel: 'ChoseMessageFromModal',
            });
          },
        ];
        pixels.forEach((e) => { tryFunction(e); });
      },
    },
  });
};

export default TrackingManager;
