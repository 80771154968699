/* eslint-disable no-param-reassign */
/* eslint-disable */

import api from './graphql';

const getDefaultState = () => {
  return {
    checkout: {
      hasErrors: false,
      weddingSlug: null,
      items: [],
      result: {},
      config: {},
      giftCardDiscount: 0,
      discountApplied: false,
      guest: {},
      timer: {},
    },
    gift: {
      name: 'Presente',
      value: 0,
    },
  }
};

const state = getDefaultState()

const actions = {
  fetchConfig({ commit }) {
    return api.queryCheckoutConfig().then((data) => {
      commit('setConfig', { data })
    });
  },
  cleanCheckout({ commit }) {
    commit('cleanCheckoutData');
  },
  resetCheckoutState({ commit }) {
    commit('resetCheckoutState');
  },
  getMessages({ commit }, slug){
    return api.getMessagesFromApi(slug);
  },
  submitCheckout({ commit, state}) {
    let result = "";

    console.log({checkout: state.checkout})

    if (state.checkout.items.length > 0) {
      result = api.createCart(state.checkout);
    } else {
      result = api.createCartWithValue({...state.checkout, ...state.gift});
    }

    return result.then((data) => {
      const result = data.cartCheckout;
      commit('checkoutDone', result);
      return result;
    });
  },
  prepareCheckout({ commit }, data) {
    commit('addCheckoutData', data);
  },
};

const mutations = {
  addTimer(state,obj) {
    state.checkout.timer = obj;
  },
  addWeddingSlug(state, slug) {
    state.checkout.weddingSlug = slug;
  },
  addGuest(state, guest) {
    state.checkout.guest = guest;
  },
  addCardType(state, cardType) {
    state.checkout.cardType = cardType;
  },
  addCartItem(state, item) {
    state.checkout.items.push({
      uuid: item.uuid,
      quotas: parseInt(item.quotas)
    });
  },
  addCheckoutData(state, data) {
    const address = data.customer ? {
      city: data.customer.address.city,
      complementary: data.customer.address.complementary,
      neighborhood: data.customer.address.neighborhood,
      state: data.customer.address.state,
      street: data.customer.address.street,
      streetNumber: data.customer.address.street_number,
      zipcode: data.customer.address.zipcode,
    } : undefined;
    const customer = data.customer ? {
      address,
      documentNumber: data.customer.document_number,
      email: data.customer.email,
      name: data.customer.name,
    } : undefined;
    state.checkout.checkoutData = {
      paymentMethod: data.payment_method,
      amount: data.amount,
      cardHash: data.card_hash,
      installments: parseInt(data.installments),
      customer: customer
    };
  },
  checkoutDone(state, result) {
    const hasErrors = (result.status === 'refused');
    state.checkout.hasErrors = hasErrors;
    state.checkout.result = result;
  },
  markAsError(state, result) {
    state.checkout.hasErrors = true;
  },
  cleanCheckoutData(state) {
    state.checkout.hasErrors = false;
    state.checkout.result = {};
  },
  resetCheckoutState(state) {
    Object.assign(state.checkout, getDefaultState().checkout);
  },
  setConfig(state, config) {
    state.checkout.config = config.data;
  },
  setGiftValue(state, { value }) {
    state.gift.value = value;
  },
  setGiftName(state, name){
    state.gift.name = name;
  },
  addCardDiscount(state, discount) {
    state.checkout.giftCardDiscount = discount;
  },
  addDiscountStatus(state) {
    state.checkout.discountApplied = true;
  },
  removeDiscountStatus(state) {
    state.checkout.discountApplied = false;
  }
};

const getters = {
  checkout: state => state.checkout,
  checkoutGiftValue: state => state.gift.value,
  checkoutGiftName: state => state.gift.name,
  discountStatus: state => state.checkout.discountApplied,
  originalValue: state => state.checkout.originalValue,
  timer: state => state.checkout.timer,
};

export default {
  state,
  namespaced: true,
  actions,
  mutations,
  getters,
};
