export default class Timer {
  constructor(seconds) {
    this.minutes = Math.floor(seconds / 60) || 0;
    this.seconds = seconds % 60 || 0;
    this.interval = null;
    this.setInLocalStorage();
    this.startTimer();
  }

  timerCountDown() {
    this.interval = setInterval(() => {
      if (this.seconds > 0) {
        this.seconds -= 1;
      } else if (this.minutes > 0) {
        this.minutes -= 1;
        this.seconds = 59;
      } else {
        this.stopTimer();
      }
      this.setInLocalStorage();
    }, 1000);
  }

  startTimer() {
    clearInterval(this.interval);
    this.timerCountDown();
  }

  stopTimer() {
    clearInterval(this.interval);
  }

  setInLocalStorage() {
    window.localStorage.setItem('timer', JSON.stringify({
      minutes: this.minutes,
      seconds: this.seconds,
    }));
  }
}
