import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';

import attributionsGuards from '#/utils/router-attributions-guards';

import VWeddingSite from '#/views/v-wedding-site.vue';
import VCheckoutSummary from '#/views/checkout/v-summary.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:slug',
      component: VWeddingSite,
      children: [
        {
          path: 'checkout',
          name: 'checkout',
          component: VCheckoutSummary,
        },
      ],
    },
  ],
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? (`?${result}`) : '';
  },
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  console.log({ to, from });
  next();
});

router.beforeEach(attributionsGuards);

export default router;
