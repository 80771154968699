<template>
  <core-box
    direction="row"
    class="system-notification"
    align-items="center"
    padding="5 4 5"
  >
    <core-box
      class="notification__icon"
      margin="0 4 0 0"
      grow="0"
    >
      <components :is="types[type].icon"/>
    </core-box>
    <core-box
      grow="0"
    >
      <core-text
        size="small"
        type="cta"
      >
        {{ title || types[type].title }}
      </core-text>
      <core-text
        size="small"
        type="body"
      >
        {{ text }}
      </core-text>
    </core-box>
  </core-box>
</template>

<script>
import SuccessSvg from '@wedy/legacy-assets/images/icons/circle_success.svg';
import WarningSvg from '@wedy/legacy-assets/images/icons/circle_warning.svg';
import ErrorSvg from '@wedy/legacy-assets/images/icons/circle_error.svg';
import CoreText from '../core/core-text';
import CoreBox from '../core/core-box';

const SUCCESS_TYPE = 'success';
const WARNING_TYPE = 'warn';
const ERROR_TYPE = 'error';

export default {
  name: 'ANotificationSimple',
  components: {
    CoreBox,
    CoreText,
  },
  props: {
    type: {
      types: String,
      required: true,
      validator: (value) => [SUCCESS_TYPE, WARNING_TYPE, ERROR_TYPE].includes(value),
    },
    title: {
      types: String,
      default: undefined,
    },
    text: {
      types: String,
      required: true,
    },
  },
  data() {
    return {
      types: {
        success: {
          icon: SuccessSvg,
          title: 'Sucesso',
        },
        warn: {
          icon: WarningSvg,
          title: 'Aviso!',
        },
        error: {
          icon: ErrorSvg,
          title: 'Erro',
        },
      },
    };
  },
};
</script>

<style lang="stylus">
.system-notification
  border 0.5px solid var(--grey-light)
  background-color var(--light-color)
  box-shadow 0px 2px 30px rgba(0, 0, 0, 0.05)
  border-radius 5px

  .notification__icon
    min-width 50px
</style>
