<template>
  <div>
    <div
      ref="placeholder"
      :style="stylePlaceholder"
    />
    <div
      ref="content"
      :style="styleContent"
    >
      <slot
        :is-sticky="(isStickyToBottom && !isAtBottom) || (isStickyToTop && !isAtTop)"
        :is-sticky-to-bottom="(isStickyToBottom && !isAtBottom)"
        :is-sticky-to-top="(isStickyToTop && !isAtTop)"
      />
    </div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle';

import getScrollParent from '../utils/get-scroll-parent';

const DEFAULT_STYLE_CONTENT = {
  position: 'static',
  top: 'auto',
  bottom: 'auto',
  left: 'auto',
  width: 'auto',
  zIndex: 'auto',
};

const DEFAULT_STYLE_PLACEHOLDER = {
  paddingTop: 0,
};

const EVENTS = [
  'scroll',
  'touchstart',
  'touchmove',
  'touchend',
];

const EVENTS_WINDOW = [
  'resize',
  'load',
  'pageshow',
];

const DEFAULT_OFFSET_BOTTOM = 24;

const DEFAULT_OFFSET_TOP = 24;

export default {
  name: 'ASticky',
  props: {
    bottom: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 0,
    },
    offsetBottom: {
      type: Number,
      default: DEFAULT_OFFSET_BOTTOM,
    },
    offsetTop: {
      type: Number,
      default: DEFAULT_OFFSET_TOP,
    },
    top: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: undefined,
    },
    boundToParent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAtBottom: false,
      isAtTop: false,
      isStickyToBottom: false,
      isStickyToTop: false,
      styleContent: {},
      stylePlaceholder: {},
    };
  },
  mounted() {
    if (this.delay) {
      setTimeout(() => {
        this.bind();
      }, this.delay);
    } else {
      this.bind();
    }
  },
  destroyed() {
    this.unbind();
  },
  methods: {
    bind() {
      const scrollParent = getScrollParent(this.$refs.content);

      this._eventsTarget = scrollParent === document.body ? window : scrollParent;
      this._scrollParent = scrollParent;

      if (!this._eventsTarget || !this._scrollParent) {
        return;
      }

      EVENTS.forEach((event) => {
        this._eventsTarget.addEventListener(event, this.update, { passive: true });
      });

      EVENTS_WINDOW.forEach((event) => {
        window.addEventListener(event, this.update, { passive: true });
      });

      this.$nextTick(() => {
        this.update();
      });
    },
    shouldStickToTop({
      contentHeight,
      contentOffsetBottom,
      contentTop,
      parentOffsetBottom,
      placeholderTop,
      scrollContainerBottom,
    }) {
      if (!this.top) {
        return false;
      }

      const bottomReference = scrollContainerBottom;
      const topReference = placeholderTop;

      const shouldStickToTop = (
        topReference <= this.offsetTop
        && bottomReference >= this.offsetBottom
      );

      if (!shouldStickToTop) {
        return false;
      }

      const isAtTop = (
        topReference === this.offsetTop
      );

      const isFrozen = (
        this.boundToParent
        && contentTop <= this.offsetTop
        && contentOffsetBottom >= parentOffsetBottom
      );

      return {
        isAtTop,
        isStickyToTop: true,
        styleContent: {
          position: isFrozen
            ? 'absolute'
            : 'fixed',
          top: isFrozen
            ? `${parentOffsetBottom - contentHeight}px`
            : `${this.offsetTop}px`,
        },
      };
    },
    shouldStickToBottom({
      contentHeight,
      placeholderTop,
      scrollContainerTop,
      windowHeight,
    }) {
      if (!this.bottom) {
        return false;
      }

      const bottomReference = windowHeight - placeholderTop - contentHeight;
      const topReference = windowHeight - scrollContainerTop;

      const shouldStickToBottom = (
        bottomReference <= this.offsetBottom
        && topReference >= this.offsetTop
      );

      if (!shouldStickToBottom) {
        return false;
      }

      const isAtBottom = (
        bottomReference === this.offsetBottom
      );

      return {
        isAtBottom,
        isStickyToBottom: true,
        styleContent: {
          position: 'fixed',
          bottom: `${this.offsetBottom}px`,
        },
      };
    },
    update: throttle(function _update() {
      const {
        bottom: contentBottom,
        height: contentHeight,
        top: contentTop,
      } = this.$refs.content.getBoundingClientRect();

      const {
        bottom: parentBottom,
      } = this.$el.parentElement.getBoundingClientRect();

      const {
        left: placeholderLeft,
        top: placeholderTop,
        width: placeholderWidth,
      } = this.$refs.placeholder.getBoundingClientRect();

      const {
        bottom: scrollContainerBottom,
        top: scrollContainerTop,
      } = this._scrollParent.getBoundingClientRect();

      const {
        scrollTop: documentScrollTop,
      } = document.documentElement;

      const {
        innerHeight: windowHeight,
        scrollY: windowScrollY,
        pageYOffset: windowPageYOffset,
      } = window;

      const scrollPosition = windowScrollY || windowPageYOffset || documentScrollTop;

      const measurements = {
        contentBottom,
        contentHeight,
        contentOffsetBottom: contentBottom + scrollPosition,
        contentTop,
        parentBottom,
        parentOffsetBottom: parentBottom + scrollPosition,
        placeholderLeft,
        placeholderTop,
        placeholderWidth,
        scrollContainerBottom,
        scrollContainerTop,
        windowHeight,
      };

      const {
        isAtBottom = false,
        isAtTop = false,
        isStickyToBottom = false,
        isStickyToTop = false,
        styleContent = {},
      } = this.shouldStickToTop(measurements) || this.shouldStickToBottom(measurements);

      this.styleContent = {
        ...DEFAULT_STYLE_CONTENT,
        ...styleContent,
        ...((isStickyToBottom || isStickyToTop) && {
          left: `${placeholderLeft}px`,
          width: `${placeholderWidth}px`,
          zIndex: this.zIndex || DEFAULT_STYLE_CONTENT.zIndex,
        }),
      };

      this.stylePlaceholder = {
        ...DEFAULT_STYLE_PLACEHOLDER,
        ...((isStickyToBottom || isStickyToTop) && {
          paddingTop: `${contentHeight}px`,
        }),
      };

      this.isAtBottom = isAtBottom;
      this.isAtTop = isAtTop;
      this.isStickyToTop = isStickyToTop;
      this.isStickyToBottom = isStickyToBottom;
    }, 10),
    unbind() {
      if (!this._eventsTarget || !this._scrollParent) {
        return;
      }

      EVENTS.forEach((event) => {
        this._eventsTarget.removeEventListener(event, this.update, { passive: true });
      });

      EVENTS_WINDOW.forEach((event) => {
        window.removeEventListener(event, this.update, { passive: true });
      });

      this._eventsTarget = undefined;
      this._scrollParent = undefined;
    },
  },
};
</script>
