import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import checkout from './checkout';
import wedding from './wedding';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    checkout, wedding,
  },
  plugins: [
    createPersistedState({
      key: window.location.pathname.split('/')[1],
      paths: ['checkout.gift', 'checkout.checkout.guest', 'giftlist'],
    }),
  ],
});
