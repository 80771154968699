import throttle from 'lodash/throttle';
import Vue from 'vue';

const viewportlyState = Vue.observable({
  activeCount: 0,
  isActive: false,
  isViewportDesktop: false,
  isViewportMobile: false,
  viewportHeight: undefined,
  viewportWidth: undefined,
});

const viewportlyCheck = throttle(() => {
  if (window.innerWidth < 860) {
    viewportlyState.isViewportDesktop = false;
    viewportlyState.isViewportMobile = true;
  } else {
    viewportlyState.isViewportDesktop = true;
    viewportlyState.isViewportMobile = false;
  }

  viewportlyState.viewportHeight = document.documentElement.clientHeight;
  viewportlyState.viewportWidth = window.innerWidth;
}, 100);

function viewportlyBind() {
  viewportlyState.activeCount += 1;

  if (
    viewportlyState.activeCount > 0
    && !viewportlyState.isActive
  ) {
    viewportlyCheck();
    window.addEventListener('resize', viewportlyCheck);
    viewportlyState.isActive = true;
  }
}

function viewportlyUnbind() {
  viewportlyState.activeCount -= 1;

  if (
    viewportlyState.activeCount === 0
    && viewportlyState.isActive
  ) {
    window.removeEventListener('resize', viewportlyCheck);
    viewportlyState.isActive = false;
  }
}

export default {
  computed: {
    isViewportDesktop() {
      return viewportlyState.isViewportDesktop;
    },
    isViewportMobile() {
      return viewportlyState.isViewportMobile;
    },
    viewportHeight() {
      return viewportlyState.viewportHeight;
    },
    viewportWidth() {
      return viewportlyState.viewportWidth;
    },
  },
  created() {
    viewportlyBind();
  },
  beforeDestroy() {
    viewportlyUnbind();
  },
};
