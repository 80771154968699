<template>
  <div
    ref="lavContainer"
    :style="style"
  />
</template>

<script>
// eslint-disable-next-line
import lottie from 'lottie-web/build/player/lottie_light.min.js';

export default {
  name: 'ALottie',
  props: {
    options: {
      type: Object,
      required: true,
    },
    height: String,
    width: String,
  },
  data() {
    return {
      style: {
        width: this.width,
        height: this.height,
        overflow: 'hidden',
        margin: '0 auto',
      },
    };
  },
  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lavContainer,
      renderer: 'svg',
      progressiveLoad: true,
      preserveAspectRatio: true,
      className: 'm-animation',
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.options.animationData,
      rendererSettings: this.options.rendererSettings,
    });
    this.$emit('animCreated', this.anim);
  },
};
</script>
