<template>
  <m-modal-empty
    width="600px"
    :full-screen="isViewportMobile"
    :has-exit="!isCreditCard"
    @closeModal="$emit('close')"
  >
    <core-box
      :is-container="isViewportMobile"
      padding="4 0"
    >
      <div>
        <template v-if="isApproved">
          <core-box
            align-items="center"
            grow="0"
            padding="0 0 12"
          >
            <m-animation
              :width="imgWidth"
              height="200px"
              :looping="false"
              name="success"
            />

            <core-text
              type="heading"
              size="small"
              align="center"
            >
              Tudo certo!
            </core-text>
            <core-box margin="6 0">
              <a-balance-text>
                <core-text
                  align="center"
                  color="grey-medium"
                >
                  O pagamento foi confirmado! <br>
                  Logo seu presente será recebido por {{ coupleName }} 💜
                </core-text>
              </a-balance-text>
            </core-box>
            <core-box>
              <a-button
                :to="backTo"
                type="button"
                color="main"
                target="_self"
              >
                <template v-slot:icon>
                  <monitor-svg/>
                </template>
                Voltar ao site
              </a-button>
            </core-box>
          </core-box>
        </template>

        <template v-else>
          <m-pre-approval @statusChanged="handleStatusChange"/>
        </template>
      </div>
    </core-box>
  </m-modal-empty>
</template>

<script>
import { mapGetters } from 'vuex';
import ABalanceText from '@wedy/legacy-components/atoms/a-balance-text.vue';
import CoreBox from '@wedy/legacy-components/core/core-box.vue';
import CoreText from '@wedy/legacy-components/core/core-text.vue';
import viewportly from '@wedy/legacy-components/mixins/viewportly';
import AButton from '@wedy/legacy-components/atoms/a-button.vue';
import MAnimation from '@wedy/legacy-components/molecules/m-animation.vue';
import MModalEmpty from '@wedy/legacy-components/molecules/m-modal-empty';
import MonitorSvg from '@wedy/legacy-assets/images/icons/monitor.svg';
import MPreApproval from '#/views/checkout/m-pre-approval.vue';

export default {
  name: 'OSuccess',
  components: {
    ABalanceText,
    AButton,
    CoreBox,
    CoreText,
    MAnimation,
    MModalEmpty,
    MPreApproval,
    MonitorSvg,
  },
  mixins: [
    viewportly,
  ],
  props: {
    backTo: undefined,
  },
  data() {
    return {
      preApprovalStatus: undefined,
    };
  },
  computed: {
    ...mapGetters('checkout', ['checkout']),
    ...mapGetters('wedding', ['wedding']),
    coupleName() {
      return this.wedding.simpleName;
    },
    isCreditCard() {
      return this.checkout.checkoutData.paymentMethod === 'credit_card';
    },
    isApproved() {
      return (
        this.isCreditCard || this.preApprovalStatus === 'completed'
      );
    },
  },
  created() {
    if (!this.checkout.checkoutData) this.$router.push({ name: '404' });
  },
  mounted() {
    window.onpopstate = () => { window.location = `${this.wedding.site_url}`; };
    this.trackingCheckoutPaymentDone(this.wedding.slug);
  },
  methods: {
    handleStatusChange(status) {
      this.preApprovalStatus = status;
    },
  },
};
</script>
