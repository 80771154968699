import gql from 'graphql-tag';
import client from '../apollo';

function exec(action, options) {
  return client[action](options).then((r) => r.data);
}

function execQuery(query, variables) {
  return exec('query', { query: gql(query), variables });
}

function execMutate(mutation, variables) {
  return exec('mutate', { mutation: gql(mutation), variables });
}

const WEDDING_INFO_SEPARATOR_DICT = {
  AMPERSAND: '&',
  AND: 'e',
  DASH: '—',
  PLUS_SIGN: '+',
};

function queryWedding({ slug }) {
  // const query = `#graphql
  //   query ($slug: String) {
  //     wedding (slug: $slug) {
  //       slug
  //       date
  //       couple_name
  //       coupleName: name(as: regular)
  //       simpleName: name(as: simple)
  //       ogImageUrl
  //       site_url
  //       thumb_url
  //     }
  //   }
  // `;

  const query = `#graphql
    query ($input: WebsiteInput!) {
      website (input: $input) {
        slug
        image
        url
        info {
          date
          separator
          people {
            name
            surname
          }
        }
      }
    }
  `;

  return execQuery(query, {
    input: {
      slug,
    },
  }).then((data) => {
    const names = data.website.info.people.map((person) => person.name).join(` ${WEDDING_INFO_SEPARATOR_DICT[data.website.info.separator]} `);

    return {
      slug: data.website.slug,
      date: data.website.info.date,
      couple_name: names,
      coupleName: names,
      simpleName: names,
      ogImageUrl: data.website.image,
      site_url: data.website.url,
      thumb_url: data.website.image,
    };
  });
}

function queryCheckoutConfig() {
  const query = '{ cart_checkout_config { crypto_hash interest_rate free_installments } }';
  return execQuery(query).then((data) => data.cart_checkout_config);
}

function createCart({
  weddingSlug, guest, cardType, giftCardDiscount, items, checkoutData,
}) {
  const mutation = `
    mutation cartCheckout(
      $wedding_slug: String!
      $guest_name: String!
      $guest_email: String!
      $guest_message: String!
      $gift_card_type: String!
      $gift_card_discount: Int
      $cart_items: [CartItemAttributes!]
      $checkout_data: CheckoutDataAttributes!
    ) {
      cartCheckout(
        wedding_slug: $wedding_slug,
        guest_name: $guest_name,
        guest_email: $guest_email,
        guest_message: $guest_message,
        gift_card_type: $gift_card_type,
        gift_card_discount: $gift_card_discount,
        cart_items: $cart_items,
        checkout_data: $checkout_data,
      ) { status pix_qrcode identifier }
    }`;
  return execMutate(mutation, {
    wedding_slug: weddingSlug,
    guest_name: guest.name,
    guest_email: guest.email,
    guest_message: guest.message,
    gift_card_type: cardType,
    gift_card_discount: giftCardDiscount,
    cart_items: items,
    checkout_data: checkoutData,
  });
}

function createCartWithValue({
  weddingSlug, guest, cardType, giftCardDiscount, value, name, checkoutData,
}) {
  const mutation = `
    mutation cartCheckout(
      $wedding_slug: String!
      $guest_name: String!
      $guest_email: String!
      $guest_message: String!
      $gift_card_type: String!
      $gift_card_discount: Int
      $cart_value: Float
      $gift_name: String
      $checkout_data: CheckoutDataAttributes!
    ) {
      cartCheckout(
        wedding_slug: $wedding_slug,
        guest_name: $guest_name,
        guest_email: $guest_email,
        guest_message: $guest_message,
        gift_card_type: $gift_card_type,
        gift_card_discount: $gift_card_discount,
        cart_value: $cart_value,
        gift_name: $gift_name,
        checkout_data: $checkout_data,
      ) { status pix_qrcode identifier }
    }`;
  return execMutate(mutation, {
    wedding_slug: weddingSlug,
    guest_name: guest.name,
    guest_email: guest.email,
    guest_message: guest.message,
    gift_card_type: cardType,
    gift_card_discount: giftCardDiscount,
    cart_value: value,
    gift_name: name,
    checkout_data: checkoutData,
  });
}

function fetchApartmentGiftRegistry({ slug }) {
  const query = `{ gift_registry(wedding_slug: "${slug}") {
        progress
        has_bank_account_register
        gift_registries {
          progress
          title
          description
          apartment {
            size
            rooms
            suites
            garages
            slug
            enterprise {
              name
              description
              enterprise_type
              enterprise_address {
                street
                neighborhood
              }
              enterprise_photos {
                thumb_url
                main
                large_url
              }
            }
          }
          gifts {
            progress
            final_value
            image_to_show_url
            name
          }
        }
      }
    }`;
  return execQuery(query);
}

function getMessagesFromApi(slug) {
  const query = 'query($slug: String!){ weddingMessage(slug: $slug) }';
  const variables = { slug };
  return execQuery(query, variables);
}

export default {
  queryCheckoutConfig,
  queryWedding,
  createCart,
  createCartWithValue,
  fetchApartmentGiftRegistry,
  getMessagesFromApi,
};
