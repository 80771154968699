<template>
  <transition name="fade">
    <div class="modal-overlay">
      <div
        :class="modalContentClasses"
        :style="{
          'width': fullScreen ? '100%' : width,
          'max-width': maxWidth
        }"
      >
        <div v-if="hasExit">
          <div
            v-if="!fullScreen"
            class="close-button"
          >
            <div
              class="close-button-border"
              @click="closeModal"
            >
              <close-svg/>
            </div>
          </div>
          <div
            v-else
            class="back-button"
          >
            <a-back-header @backHasBeenClicked="closeModal"/>
          </div>
        </div>
        <slot/>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseSvg from '@wedy/legacy-assets/images/icons/close.svg';
import ABackHeader from '../atoms/a-back-header';

export default {
  name: 'MModalEmpty',
  components: {
    ABackHeader,
    CloseSvg,
  },
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '50%',
    },
    maxWidth: {
      type: String,
      default() {
        return 'unset';
      },
    },
    hasExit: {
      type: Boolean,
      default: true,
    },
    hasOverflow: {
      type: Boolean,
      default: true,
    },
    hasMaxHeight: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    modalContentClasses() {
      return {
        'modal-content': true,
        'modal-max-height': this.hasMaxHeight,
        'full-screen': this.fullScreen,
        'modal-overflow': this.hasOverflow,
      };
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang='stylus' scoped>
.modal-overlay
  display flex
  align-items center
  justify-content center
  top 0
  left 0
  position fixed
  z-index 500
  width 100%
  height 100vh
  background rgba(0,0,0,0.6)

  .modal-overflow
    overflow auto

  .modal-max-height
    max-height 80%

  .modal-content
    border-radius 10px
    background-color #ffffff

    &.full-screen
      max-height unset
      max-width unset
      overflow auto
      height 100%
      border-radius unset

    .close-button
      display flex
      justify-content flex-end
      .close-button-border
        width 2rem
        height 2rem
        display flex
        justify-content center
        position absolute
        background #fff
        border-radius 100px
        box-shadow 0 3px 6px 0 rgba(0, 0, 0, 0.16)
        cursor pointer
        transform translate(1rem, -1rem)

        svg
          width 0.7rem
          margin 0
          fill #707070

    .back-button
      display flex
      align-items center
      justify-content center
      position absolute
      top 5px
      left 12px
      cursor pointer
      margin-bottom 10px

.share-modal-enter
  opacity 0

.share-modal-leave-active
  opacity 0

.fade-enter-active, .fade-leave-active
  transition opacity .3s
.fade-enter, .fade-leave-to
  opacity 0
</style>
