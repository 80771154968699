const EMPTY_STATE = {
  has_gift_registry: false,
  is_bank_account_registered: false,
  is_documents_missing: false,
  is_document_missing_on_approval: false,
  is_confirmed: false,
  push_notifications_enabled: false,
};

export default {
  computed: {
    authVue() { return this.$auth; },
    currentUser() { return this.authVue ? this.authVue.user() : null; },
    currentUserState() { return this.currentUser.self_state || EMPTY_STATE; },
    authenticated() { return this.authVue.check(); },
  },
  methods: {
    fetchUserData() {
      this.$auth.fetch();
    },
  },
};
