<script>
const isValidString = (string) => typeof string === 'string' && string.length;
const isValidLocation = (location) => typeof location === 'object' && (isValidString(location.name) || isValidString(location.path));

const DEFAULT_DIRECTION = 'horizontal';

export default {
  name: 'AButton',
  inheritAttrs: false,
  props: {
    direction: {
      type: String,
      default: DEFAULT_DIRECTION,
      validator: (value) => ['horizontal', 'vertical'].includes(value),
    },
    alignment: {
      type: String,
      default: 'center',
      validator: (value) => ['center', 'left', 'right'].includes(value),
    },
    iconReversed: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'main',
      validator: (value) => ['black', 'google-blue', 'gray', 'main', 'neutral', 'white', 'alert', 'success'].includes(value),
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'full',
      validator: (value) => ['full', 'bordered', 'ghost', 'translucent', 'selected'].includes(value),
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium', 'medium-large', 'large'].includes(value),
    },
    target: {
      type: String,
      default: '_blank',
      validator: (value) => ['_blank', '_self'].includes(value),
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'submit'].includes(value),
    },
    to: {
      type: [Object, String],
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        button: true,
        [`size-${this.size}`]: true,
        [`mode-${this.mode}`]: true,
        [`color-${this.color}`]: true,
        [`alignment-${this.alignment}`]: this.direction === DEFAULT_DIRECTION,
        [`direction-${this.direction}`]: this.direction !== DEFAULT_DIRECTION,
        'icon-reversed': this.iconReversed,
        'is-block': this.isBlock,
        'is-disabled': this.isDisabled,
        'is-hoverable': !this.isDisabled && !this.isLoading,
        'is-loading': this.isLoading,
        'is-shadowed': this.shadow,
      };
    },
    iconAndLabelReversed() {
      return this.iconReversed ? '-reversed' : '';
    },
  },
  methods: {
    onClick() {
      if (!this.isDisabled && !this.isLoading) {
        this.$emit('click');
      }
    },
  },
  render(h) {
    const slotIcon = (this.$scopedSlots.icon && this.$scopedSlots.icon()) || [];
    const slot = (this.$scopedSlots.default && this.$scopedSlots.default()) || [];

    let component = 'button';

    if (isValidLocation(this.to)) {
      component = 'router-link';
    } else if (isValidString(this.to)) {
      if (this.to.startsWith('/')) {
        component = 'router-link';
      } else {
        component = 'a';
      }
    }

    return h(component, {
      class: {
        ...this.classes,
        'with-icon': !this.isLoading && slotIcon.length && !slot.length,
        [`with-icon-and-label${this.iconAndLabelReversed}`]: !this.isLoading && slotIcon.length && slot.length,
        'with-label': !this.isLoading && !slotIcon.length && slot.length,
      },
      ...!this.isDisabled && {
        [component === 'router-link' ? 'nativeOn' : 'on']: {
          ...this.$listeners,
        },
      },
      props: {
        to: component === 'router-link' ? this.to : undefined,
      },
      attrs: {
        href: component === 'a' ? this.to : undefined,
        target: component === 'a' ? this.target : undefined,
        type: component === 'button' ? this.type : undefined,
      },
    }, [
      h('span', {
        class: 'wrapper',
      }, [
        this.isLoading && h('div', { class: 'loader' }, [
          h('div', { class: 'circle' }),
          h('div', { class: 'circle' }),
          h('div', { class: 'circle' }),
        ]),
        !this.isLoading && slotIcon.length && h('span', { class: 'icon' }, [
          ...slotIcon,
        ]),
        !this.isLoading && slot.length && h('span', { class: 'label' }, [
          ...slot,
        ]),
      ].filter(Boolean)),
    ]);
  },
};
</script>

<style lang="stylus" scoped>
button_size(min_height, font_size, font_weight)
  dot_size = font_size / 2
  icon_size = min_height / 2
  line_height = floor(font_size * 1.25)
  dot_margin = (min_height - dot_size) / 2
  icon_margin = (min_height - icon_size) / 2
  label_margin = (min_height - line_height) / 2
  min-height min_height
  border-radius var(--button-border-radius)

  .loader
    margin dot_margin

    .circle
      width dot_size
      height dot_size

      & + .circle
        margin-left ceil(dot_size / 3)

  .icon
    width icon_size
    height icon_size

  .label
    font-size unit(font_size / 16px, 'rem')
    line-height unit(line_height / 16px, 'rem')
    font-weight font_weight

  &:not(.direction-vertical)

    &.with-icon
      .icon
        margin icon_margin

    &.with-icon-and-label
      .icon
        margin icon_margin (icon_margin * 0.75) icon_margin icon_margin

      .label
        margin label_margin (label_margin * 1.25) label_margin 0

    &.with-icon-and-label-reversed
      .icon
        margin icon_margin  icon_margin icon_margin (icon_margin * 0.75)

      .label
        margin label_margin 0 label_margin (label_margin * 1.25)

    &.with-label
      .label
        margin label_margin (label_margin * 1.25)

  &.direction-vertical

    &.with-icon
      .icon
        margin icon_margin

    &.with-icon-and-label
      .icon
        margin icon_margin icon_margin (icon_margin * 0.75) icon_margin

      .label
        margin 0 (label_margin * 1.25) label_margin (label_margin * 1.25)

    &.with-icon-and-label-reversed
      .icon
        margin (icon_margin * 0.75) icon_margin icon_margin icon_margin

      .label
        margin label_margin (label_margin * 1.25) 0 (label_margin * 1.25)

    &.with-label
      .label
        margin label_margin (label_margin * 1.25)

.button
  reset_button()
  transition opacity .3s
  display inline-block

  &.is-hoverable:hover
    opacity .8

  &.is-block
    width 100%

  &.is-disabled
    cursor not-allowed
    opacity .4

  &.is-loading
    cursor not-allowed

  &.is-shadowed
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  &:not(.direction-vertical)

    .wrapper
      display flex
      flex-direction row
      align-items center

    &.icon-reversed
      .wrapper
        flex-direction row-reverse

  &.direction-vertical

    .wrapper
      display flex
      flex-direction column
      align-items center
      justify-content center

    &.icon-reversed
      .wrapper
        flex-direction column-reverse

  .loader
    flex 1 1 auto
    display flex
    justify-content center
    align-items center

    .circle
      background-color currentColor
      border-radius 50%

      &:nth-last-child(1)
        animation loader .75s .125s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite

      &:nth-last-child(2)
        animation loader .75s .25s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite

      &:nth-last-child(3)
        animation loader .75s .375s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite

  .icon
    flex 0 0 auto

    >>> svg
      display block
      width 100%
      height 100%

  .label
    flex 0 0 auto

  &.size-small
    button_size(
      min_height: get_spacing(8),
      font_size: 14px,
      font_weight: 500
    )

  &.size-medium
    button_size(
      min_height: get_spacing(12),
      font_size: 16px,
      font_weight: 600,
    )

  &.size-medium-large
    button_size(
      min_height: get_spacing(16),
      font_size: 16px,
      font_weight: 600,
    )

  &.size-large
    button_size(
      min_height: get_spacing(16),
      font_size: 18px,
      font_weight: 600,
    )

  &.mode-full
    --button-shadow 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.02)

    &.color-main
      background-color var(--main-color)
      color var(--light-color)
      box-shadow var(--button-shadow)

    &.color-gray
      background-color var(--grey-medium)
      color var(--light-color)
      box-shadow var(--button-shadow)

    &.color-neutral
      background-color var(--light-color)
      color var(--dark-color)
      box-shadow var(--button-shadow)

    &.color-white
      background-color var(--light-color)
      color var(--main-color)
      box-shadow var(--button-shadow)

    &.color-alert
      background-color var(--alert-color)
      color var(--light-color)

    &.color-success
      background-color var(--success-color)
      color var(--light-color)
      box-shadow var(--button-shadow)

    &.color-google-blue
      background-color var(--google-blue-color)
      color var(--light-color)
      box-shadow var(--button-shadow)

    &.color-black
      background-color black
      color var(--light-color)
      box-shadow var(--button-shadow)

  &.mode-bordered
    --button-shadow 0 4px 8px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.015)

    &.color-main
      box-shadow inset 0 0 0 1px var(--main-color), var(--button-shadow)
      color var(--main-color)

    &.color-gray
      box-shadow inset 0 0 0 1px var(--grey-medium), var(--button-shadow)
      color var(--grey-medium)

    &.color-neutral
      box-shadow inset 0 0 0 1px var(--grey-light), var(--button-shadow)
      color var(--dark-color)

    &.color-white
      box-shadow inset 0 0 0 1px var(--light-color), var(--button-shadow)
      color var(--light-color)

    &.color-alert
      box-shadow inset 0 0 0 1px var(--grey-light), var(--button-shadow)
      color var(--alert-color)

    &.color-success
      box-shadow inset 0 0 0 1px var(--success-color), var(--button-shadow)
      color var(--success-color)

    &.color-google-blue
      box-shadow inset 0 0 0 1px var(--google-blue-color), var(--button-shadow)
      color var(--google-blue-color)

    &.color-black
      box-shadow inset 0 0 0 1px black, var(--button-shadow)
      color black

  &.mode-ghost
    &.color-main
      color var(--main-color)

    &.color-gray
      color var(--grey-medium)

    &.color-neutral
      color var(--dark-color)

    &.color-white
      color var(--light-color)

    &.color-alert
      color var(--alert-color)

    &.color-success
      color var(--success-color)

    &.color-google-blue
      color var(--google-blue-color)

    &.color-black
      color black

  &.mode-translucent
    &.color-white
      color rgba(255, 255, 255, 1)
      box-shadow inset 0 0 0 1px rgba(255, 255, 255, 0.185)
      background-color rgba(255, 255, 255, 0.085)

  &.mode-selected
    background-color var(--pastel-blue)

    &.color-main
      color var(--main-color)
      background-color var(--main-color-selection)

    &.color-neutral
      color var(--dark-color)

  &:not(.direction-vertical)

    &.alignment-left .wrapper
      justify-content flex-start
      text-align left

    &.alignment-center .wrapper
      justify-content center
      text-align center

    &.alignment-right .wrapper
      justify-content flex-end
      text-align right

@keyframes loader
  0%
    transform translate(0, -30%)

  50%
    transform translate(0, 30%)

  100%
    transform translate(0, -30%)
</style>
