<template>
  <div :class="classes">
    <slot/>
  </div>
</template>

<script>
import { normalizeResponsiveProp } from '../utils/responsive-prop';

const DEFAULT_ALIGN_ITEMS = 'stretch';
const DEFAULT_DIRECTION = 'column';
const DEFAULT_GROW = '1';
const DEFAULT_JUSTIFY_CONTENT = 'flex-start';
const DEFAULT_SHRINK = '1';

const normalizeSpacings = (spacings, prefix) => {
  if (
    !(typeof spacings === 'string' && spacings.length)
    && !(typeof spacings === 'number')
  ) {
    return [];
  }

  let all;
  let x;
  let y;

  let [top, right, bottom, left] = `${spacings}`.split(' ');

  right = right || top;
  bottom = bottom || top;
  left = left || right || top;

  top = top === '0' ? undefined : top;
  right = right === '0' ? undefined : right;
  bottom = bottom === '0' ? undefined : bottom;
  left = left === '0' ? undefined : left;

  if (right === left) {
    x = right;
    right = undefined;
    left = undefined;
  }

  if (top === bottom) {
    y = top;
    top = undefined;
    bottom = undefined;
  }

  if (x === y) {
    all = x;
    x = undefined;
    y = undefined;
  }

  return [
    [all, ''],
    [x, 'x'],
    [y, 'y'],
    [top, 't'],
    [right, 'r'],
    [bottom, 'b'],
    [left, 'l'],
  ]
    .filter(([value]) => typeof value !== 'undefined')
    .map(([value, suffix]) => {
      const normalizedValue = value.replace(/^-/, '');

      return value.startsWith('-')
        ? `-${prefix}${suffix}-${normalizedValue}`
        : `${prefix}${suffix}-${normalizedValue}`;
    });
};

export default {
  name: 'CoreBox',
  props: {
    alignContent: {
      type: [Array, String],
      default: undefined,
    },
    alignItems: {
      type: [Array, String],
      default: DEFAULT_ALIGN_ITEMS,
    },
    alignSelf: {
      type: [Array, String],
      default: undefined,
    },
    direction: {
      type: [Array, String],
      default: DEFAULT_DIRECTION,
    },
    gap: {
      type: [Array, Number, String],
      default: undefined,
    },
    grow: {
      type: [Array, Number, String],
      default: DEFAULT_GROW,
    },
    isContainer: {
      type: [Array, Boolean],
      default: false,
    },
    justifyContent: {
      type: [Array, String],
      default: DEFAULT_JUSTIFY_CONTENT,
    },
    margin: {
      type: [Array, Number, String],
      default: undefined,
    },
    padding: {
      type: [Array, Number, String],
      default: undefined,
    },
    shrink: {
      type: [Array, Number, String],
      default: DEFAULT_SHRINK,
    },
    width: {
      type: [Array, Number, String],
      default: undefined,
    },
  },
  computed: {
    classes() {
      const normalizedAlignContent = normalizeResponsiveProp(
        this.alignContent,
        (rawAlignContent) => (
          rawAlignContent
            ? `align-content-${rawAlignContent}`
            : undefined
        ),
      );

      const normalizedAlignItems = normalizeResponsiveProp(
        this.alignItems,
        (rawAlignItems) => (
          rawAlignItems && rawAlignItems !== DEFAULT_ALIGN_ITEMS
            ? `align-items-${rawAlignItems}`
            : undefined
        ),
      );

      const normalizedAlignSelf = normalizeResponsiveProp(
        this.alignSelf,
        (rawAlignSelf) => (
          rawAlignSelf
            ? `align-self-${rawAlignSelf}`
            : undefined
        ),
      );

      const normalizedDirection = normalizeResponsiveProp(
        this.direction,
        (rawDirection) => (
          rawDirection
            ? `${rawDirection}`
            : undefined
        ),
        false,
      );

      const normalizedGap = normalizeResponsiveProp(
        this.gap,
        (rawGap) => (
          rawGap
            ? `gap-${rawGap}`
            : undefined
        ),
        false,
      );

      const normalizedGrow = normalizeResponsiveProp(
        this.grow,
        (rawGrow) => (
          rawGrow && `${rawGrow}` !== DEFAULT_GROW
            ? `grow-${rawGrow}`
            : undefined
        ),
      );

      const normalizedIsContainer = normalizeResponsiveProp(
        this.isContainer,
        (rawIsContainer) => (
          rawIsContainer === true
            ? 'is-container'
            : undefined
        ),
      );

      const normalizedJustifyContent = normalizeResponsiveProp(
        this.justifyContent,
        (rawJustifyContent) => (
          rawJustifyContent && rawJustifyContent !== DEFAULT_JUSTIFY_CONTENT
            ? `justify-content-${rawJustifyContent}`
            : undefined
        ),
      );

      const normalizedMargin = normalizeResponsiveProp(
        this.margin,
        (rawMargin) => (
          normalizeSpacings(rawMargin, 'm', true)
            .map((margin) => `${margin}`)
        ),
      );

      const normalizedPadding = normalizeResponsiveProp(
        this.padding,
        (rawPadding) => (
          normalizeSpacings(rawPadding, 'p')
            .map((padding) => `${padding}`)
        ),
      );

      const normalizedShrink = normalizeResponsiveProp(
        this.shrink,
        (rawShrink) => (
          rawShrink && `${rawShrink}` !== DEFAULT_SHRINK
            ? `shrink-${rawShrink}`
            : undefined
        ),
      );

      const normalizedWidth = normalizeResponsiveProp(
        this.width,
        (rawWidth) => (
          rawWidth
            ? `width-${rawWidth}`
            : undefined
        ),
        false,
      );

      const hasWidth = Boolean(normalizedWidth.filter(Boolean).length);

      return [
        'box',
        ...normalizedAlignContent,
        ...normalizedAlignItems,
        ...normalizedAlignSelf,
        ...normalizedDirection,
        ...normalizedGap,
        ...(!hasWidth ? normalizedGrow : []),
        ...normalizedIsContainer,
        ...normalizedJustifyContent,
        ...normalizedMargin,
        ...normalizedPadding,
        ...(!hasWidth ? normalizedShrink : []),
        ...normalizedWidth,
      ].filter(Boolean);
    },
  },
};
</script>

<style lang="stylus" scoped>
generate_container(suffix)
  &.is-container{suffix}
    width 'calc(100% - %s) !important' % (get_spacing(8))
    max-width 940px !important
    margin-left auto !important
    margin-right auto !important

    +for_up(1400px)
      max-width 1236px !important

generate_direction(suffix)
  &.column{suffix},
  &.column-reverse{suffix}

    > .box
      flex-basis auto

  &.column{suffix}
    flex-direction column

    for spacing_name, spacing_value in spacings
      &.gap-{spacing_name}{suffix}

        > .box
          margin-top 0

          & + .box
            margin-top spacing_value

  &.column-reverse{suffix}
    flex-direction column-reverse

    for spacing_name, spacing_value in spacings
      &.gap-{spacing_name}{suffix}

        > .box
          margin-bottom 0

          & + .box
            margin-bottom spacing_value

  &.row{suffix}
  &.row-reverse{suffix}

    > .box
      for width in (1..12)
        percentage = 100% * (width / 12)

        &.width-{width}{suffix}
          flex-grow 0
          flex-shrink 0
          width percentage

    for spacing_name, spacing_value in spacings
      &.gap-{spacing_name}{suffix}

        > .box

          for width in (1..12)
            percentage = 100% * (width / 12)
            gap_diff = spacing_value - (spacing_value * (width / 12))

            &.width-{width}{suffix}
              flex-grow 0
              flex-shrink 0
              width 'calc(%s - %s)' % (percentage gap_diff)

  &.row{suffix}
    flex-direction row

    for spacing_name, spacing_value in spacings
      &.gap-{spacing_name}{suffix}

        > .box
          margin-left 0

          & + .box
            margin-left spacing_value

  &.row-reverse{suffix}
    flex-direction row-reverse

    for spacing_name, spacing_value in spacings
      &.gap-{spacing_name}{suffix}

        > .box
          margin-right 0

          & + .box
            margin-right spacing_value

generate_flex(suffix)
  &.grow-0{suffix}
    flex-grow 0

  &.shrink-0{suffix}
    flex-shrink 0

  for value in center flex-end flex-start space-around space-between stretch
    &.align-content-{value}{suffix}
      align-content value

  for value in center flex-end flex-start space-around space-between
    &.align-items-{value}{suffix}
      align-items value

  for value in center flex-end flex-start stretch
    &.align-self-{value}{suffix}
      align-self value

  for value in center flex-end space-around space-between space-evenly
    &.justify-content-{value}{suffix}
      justify-content value

generate_margin(suffix)
  for spacing_name, spacing_value in spacings
    &.m-{spacing_name}{suffix}
      margin spacing_value

    &.mx-{spacing_name}{suffix}
      margin-right spacing_value
      margin-left spacing_value

    &.my-{spacing_name}{suffix}
      margin-top spacing_value
      margin-bottom spacing_value

    &.mt-{spacing_name}{suffix}
      margin-top spacing_value

    &.mr-{spacing_name}{suffix}
      margin-right spacing_value

    &.mb-{spacing_name}{suffix}
      margin-bottom spacing_value

    &.ml-{spacing_name}{suffix}
      margin-left spacing_value

  for negative_spacing_name, negative_spacing_value in negative_spacings
    &.-m-{negative_spacing_name}{suffix}
      margin negative_spacing_value

    &.-mx-{negative_spacing_name}{suffix}
      margin-right negative_spacing_value
      margin-left negative_spacing_value

    &.-my-{negative_spacing_name}{suffix}
      margin-top negative_spacing_value
      margin-bottom negative_spacing_value

    &.-mt-{negative_spacing_name}{suffix}
      margin-top negative_spacing_value

    &.-mr-{negative_spacing_name}{suffix}
      margin-right negative_spacing_value

    &.-mb-{negative_spacing_name}{suffix}
      margin-bottom negative_spacing_value

    &.-ml-{negative_spacing_name}{suffix}
      margin-left negative_spacing_value

generate_padding(suffix)
  for spacing_name, spacing_value in spacings
    &.p-{spacing_name}{suffix}
      padding spacing_value

    &.px-{spacing_name}{suffix}
      padding-right spacing_value
      padding-left spacing_value

    &.py-{spacing_name}{suffix}
      padding-top spacing_value
      padding-bottom spacing_value

    &.pt-{spacing_name}{suffix}
      padding-top spacing_value

    &.pr-{spacing_name}{suffix}
      padding-right spacing_value

    &.pb-{spacing_name}{suffix}
      padding-bottom spacing_value

    &.pl-{spacing_name}{suffix}
      padding-left spacing_value

.box
  min-width 0
  display flex
  align-items stretch
  justify-content flex-start
  flex 1 1 auto

  suffix = ''
  generate_container(suffix)
  generate_flex(suffix)
  generate_margin(suffix)
  generate_padding(suffix)

  +for_down(sm)
    suffix = '-sm'
    generate_container(suffix)
    generate_direction(suffix)
    generate_flex(suffix)
    generate_margin(suffix)
    generate_padding(suffix)

  +for_between(sm, md)
    suffix = '-md'
    generate_container(suffix)
    generate_direction(suffix)
    generate_flex(suffix)
    generate_margin(suffix)
    generate_padding(suffix)

  +for_between(md, lg)
    suffix = '-lg'
    generate_container(suffix)
    generate_direction(suffix)
    generate_flex(suffix)
    generate_margin(suffix)
    generate_padding(suffix)

  +for_up(lg)
    suffix = '-xl'
    generate_container(suffix)
    generate_direction(suffix)
    generate_flex(suffix)
    generate_margin(suffix)
    generate_padding(suffix)
</style>
