<template>
  <div class="timer">
    {{ countDown }}
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import Timer from '#/timer';

export default {
  props: {
    seconds: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('checkout', ['timer']),
    countDown() {
      return `${this.convertCountDown(this.timer.minutes)}:${this.convertCountDown(this.timer.seconds)}`;
    },
  },
  created() {
    if (window.localStorage.getItem('timer') === null) {
      const object = new Timer(this.seconds);
      this.addTimer(object);
    } else {
      const timerData = JSON.parse(localStorage.getItem('timer'));
      const seconds = (timerData.minutes * 60) + timerData.seconds;
      const object = new Timer(seconds);
      this.addTimer(object);
    }
    if (this.countDown === '00:00') {
      const object = new Timer(this.seconds);
      this.addTimer(object);
    } else {
      this.addDiscountStatus();
      this.addCardDiscount(30);
    }
    this.$root.$on('stopTimer', () => {
      this.timer.stopTimer();
    });
    this.$root.$on('startTimer', () => {
      this.timer.startTimer();
    });
  },
  methods: {
    ...mapMutations('checkout', [
      'addTimer',
      'addDiscountStatus',
      'addCardDiscount',
    ]),
    convertCountDown(value) {
      if (value < 10) {
        return `0${value}`;
      }
      return value;
    },
  },
};
</script>

<style scoped>

</style>
