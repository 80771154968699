function getDomain(hostName) {
  let domain = hostName;

  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
      .test(hostName)
  ) { return hostName; }

  if (hostName != null) {
    const parts = hostName.split('.').reverse();

    if (parts != null && parts.length > 1) {
      domain = `${parts[1]}.${parts[0]}`;
    }
  }
  return domain;
}

window.appHostDomain = process.env.VUE_APP_API_URL_OVERWRITE || `${window.location.protocol}//${process.env.VUE_APP_PLATFORM_SUBDOMAIN || ''}${getDomain(window.location.hostname)}${process.env.VUE_APP_PLATFORM_PORT || ''}`;
