<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProviderWedding',
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    this.isLoading = true;

    this.$nextTick(async () => {
      try {
        await this.fetchWedding({ slug: this.$route.params.slug });
      } catch (error) {
        console.error(error);
        this.$router.push({ name: '404' });
      }
    });

    this.isLoading = false;
  },
  methods: {
    ...mapActions('wedding', [
      'fetchWedding',
    ]),
  },
  render() {
    return this.$scopedSlots.default && this.$scopedSlots.default({
      isWeddingReady: !this.isLoading,
    });
  },
};
</script>
