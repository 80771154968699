<template>
  <transition
    v-if="isOpen"
    name="fade"
  >
    <div class="modal-overlay">
      <div class="modal-content">
        <h1 v-if="title">
          {{ title }}
        </h1>
        <slot/>
        <div
          class="close-button"
          @click="closeModal"
        >
          <close-svg/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseSvg from '@wedy/legacy-assets/images/icons/close.svg';
import viewportly from '../mixins/viewportly';

export default {
  name: 'MModal',
  components: {
    CloseSvg,
  },
  mixins: [
    viewportly,
  ],
  props: {
    title: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        window.history.pushState(null, this.title, '#modal');
      }
    },
  },
  created() {
    this.$parent.$on('showModal', this.openModal);
    this.$parent.$on('closeModal', this.closeModal);
  },
  mounted() {
    window.onpopstate = () => {
      this.closeModal();
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="stylus" scoped>
.modal-overlay
  display flex
  align-items center
  justify-content center
  top 0
  left 0
  position fixed
  z-index 200
  width 100%
  height 100vh
  background rgba(0,0,0,0.6)

  .modal-content
    display flex
    justify-content center
    align-items center
    flex-direction column
    padding 4rem 2rem
    border-radius 10px
    background-color #ffffff
    position relative
    width 85%
    max-width 35rem

  .close-button
    width 2rem
    height 2rem
    display flex
    align-items center
    justify-content center
    position absolute
    top -0.5rem
    right -0.5rem
    background #fff
    border-radius 100px
    box-shadow 0 3px 6px 0 rgba(0, 0, 0, 0.16)
    cursor pointer
</style>
