<template>
  <div
    :class="{
      'valid': value != '' && value != undefined && !error,
      'invalid': !!error,
      'noshadow': noshadow,
      'bordered': bordered,
      'readonly': readonly,
      'borde-bottom': bordeBottom,
    }"
    class="box-input"
  >
    <div class="box-input__field">
      <div class="field__container">
        <slot/>
        <span
          v-if="prefix && prefix.length"
          class="prefix"
        >
          {{ prefix }}
        </span>
        <input
          ref="input"
          v-mask="mask ? mask : ''"
          data-hj-whitelist
          :class="{
            'number': type == 'number',
            'text-centered': textCenter,
          }"
          :type="type === 'slug' ? 'text' : type"
          :placeholder="placeholder"
          :required="required"
          :tabindex="tabindex"
          :name="name"
          :value="innerValue"
          :readonly="readonly"
          :inputmode="inputmode"
          :pattern="pattern"
          :autocomplete="autocomplete"
          @keydown="$emit('keydown', $event)"
          @input="handleInput"
          @blur="handleBlur"
        >
        <verified-svg
          v-if="!hideVerified"
          class="verified"
        />
      </div>
    </div>
    <transition
      name="error-fade"
      mode="out-in"
    >
      <div
        v-if="!!error"
        class="error"
      >
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { createTextMaskInputElement } from 'text-mask-core';

import VerifiedSvg from '@wedy/legacy-assets/images/verified.svg';
import slugify from '../utils/slugify';

export default {
  name: 'AInput',
  components: {
    VerifiedSvg,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },
  props: {
    readonly: Boolean,
    validate: String,
    mask: String,
    value: String,
    required: Boolean,
    autofocus: Boolean,
    tabindex: Number,
    name: { type: String, default: '' },
    placeholder: String,
    type: {
      type: String,
      default: 'text',
      validator: (val) => (
        ['number', 'tel', 'url', 'text', 'password', 'email', 'search', 'slug'].indexOf(val) !== -1
      ),
    },
    errorText: {
      type: String,
      default: 'Erro',
    },
    error: {
      type: String,
      required: false,
    },
    noshadow: Boolean,
    bordered: Boolean,
    bordeBottom: Boolean,
    textCenter: Boolean,
    textRegular: Boolean,
    hideVerified: {
      type: Boolean,
      default: false,
    },
    textMask: {
      type: [Array, Function],
      default: undefined,
    },
    inputmode: {
      type: String,
      default: undefined,
    },
    pattern: {
      type: String,
      default: undefined,
    },
    prefix: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      innerValue: this.value,
      timer: null,
      showError: false,
    };
  },
  watch: {
    value(value) {
      if (value !== this.innerValue) {
        this.innerValue = value;
      }
    },
    textMask(newTextMask, oldTextMask) {
      if (
        !this.$refs
        || !this.$refs.input
        || this.type === 'slug'
        || (typeof this.mask === 'string' && this.mask.length)
        || isEqual(newTextMask, oldTextMask)
      ) {
        return;
      }

      if (typeof newTextMask === 'function' || Array.isArray(newTextMask)) {
        this._textMaskInputElement = createTextMaskInputElement({
          guide: false,
          mask: this.textMask,
          inputElement: this.$refs.input,
        });

        this._textMaskInputElement.update(this.innerValue);

        this.innerValue = this._textMaskInputElement.state.previousConformedValue;
        this.$refs.input.value = this.innerValue;

        this.$emit('input', this.innerValue);
      }
    },
  },
  mounted() {
    if (!this.$refs || !this.$refs.input) {
      return;
    }

    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }

    if (this.type === 'slug' || (typeof this.mask === 'string' && this.mask.length)) {
      return;
    }

    if (typeof this.textMask === 'function' || Array.isArray(this.textMask)) {
      this._textMaskInputElement = createTextMaskInputElement({
        guide: false,
        mask: this.textMask,
        inputElement: this.$refs.input,
      });

      this._textMaskInputElement.update(this.innerValue);
    }
  },
  methods: {
    handleInput(e) {
      if (this.type === 'slug') {
        const {
          value,
          selectionStart,
          selectionEnd,
        } = e.target;

        if (
          typeof selectionStart === 'number'
          && typeof selectionEnd === 'number'
          && selectionStart < value.length
          && selectionStart === selectionEnd
        ) {
          const head = value.substring(0, selectionStart);
          const tail = value.substring(selectionEnd);

          const sluggedHead = slugify(head, true);
          const sluggedTail = slugify(tail, true);

          this.innerValue = `${sluggedHead}${sluggedTail}`;

          if (this.$refs && this.$refs.input) {
            this.$refs.input.value = this.innerValue;
            this.$refs.input.setSelectionRange(sluggedHead.length, sluggedHead.length);
          }
        } else {
          this.innerValue = slugify(value, true);

          if (this.$refs && this.$refs.input) {
            this.$refs.input.value = this.innerValue;
          }
        }
      } else {
        let innerValue;

        if (this._textMaskInputElement) {
          this._textMaskInputElement.update(e.target.value);
          innerValue = this._textMaskInputElement.state.previousConformedValue;
        } else {
          innerValue = e.target.value;
        }

        this.innerValue = innerValue;

        if (this.$refs && this.$refs.input) {
          this.$refs.input.value = this.innerValue;
        }
      }

      this.$emit('input', this.innerValue);
      this.showError = false;
      window.clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showError = true;
        this.timer = null;
      }, 1000);
    },

    handleBlur(e) {
      this.$emit('blur', e.target.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
  font_error = #ff95b8

  .box-input {
    padding-bottom: 1.2rem;
    position: relative;

    .box-input__field {
      display: flex;
      align-items: center;

      .field__container{
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;

        .prefix {
          color: #999;
        }

        input {
          width: 100%;
          padding: .75rem;
          box-sizing: border-box;
          border: 1px solid transparent;
          color: #141414
          font-size: .875rem;
          font-weight: 500;
          position: relative;

          &[type="password"]  {
            letter-spacing: .3rem;

            &::placeholder {
              letter-spacing: 0;
            }
          }

          &::placeholder {
            color: #999999;
            font-weight: 500;
            opacity: 0.7;
          }

          &:focus {
            outline: none;
          }
        }

      }
    }

    &.valid {
      .verified {
        visibility: visible;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.invalid.bordered {
      .field__container {
        color: font_error;
        border: 1px solid font_error !important;
      }
    }

    &.invalid.borde-bottom {
      .field__container {
        border-bottom: 1px solid font_error;
      }
    }

    &.bordered {
      .field__container {
        border: 1px solid #d0d0d0;
      }
    }

    &.noshadow {
      .field__container {
        box-shadow: none;
      }
    }

    &.readonly {
      .field__container {
        color: #808080;
        background: #f3f3f3;
      }
    }

    &.borde-bottom {
      .field__container {
        border-bottom: 1px solid #dddddd;
        padding-left: 0;
      }

      .error {
        margin-top: 6px;
      }
    }

    .text-centered {
      text-align: center;
    }

    .error {
      margin-top: 3px;
      color: font_error;
      font-weight: 600;
      font-size: .8rem;
      bottom: 0;
      left: 0;
    }

    .verified {
      visibility: hidden;
      position: absolute;
      right: 1rem;
    }

    .error-fade-enter-active {
      animation: go-in .4s;
    }

    .error-fade-leave-active {
      animation: go-in .4s reverse;
    }

    @keyframes go-in {
      0% {
        opacity: 0;
        max-height: 0;
        tranform: translateY(-100%);
      }
      100% {
        opacity: 1;
        max-height: 100px;
        tranform: translateY(0);
      }
    }

  }
</style>
