/* eslint-disable prefer-const */

export const RESPONSIVE_PROP_SUFFIXES = [
  'sm',
  'md',
  'lg',
  'xl',
];

export const mergeResponsiveProps = (values, merger) => {
  if (!values.every((val) => Array.isArray(val) && val.length === values[0].length)) {
    return [];
  }

  const normalizedValues = values.map((val) => (
    val.map((v) => (
      v.substr(0, v.length - 3)
    ))
  ));

  return normalizedValues[0].map((v, index) => (
    merger(
      v,
      ...normalizedValues.slice(1).reduce((acc, val) => [
        ...acc,
        val[index],
      ], []),
    )
  )).map((mergedValue, index) => (
    typeof mergedValue === 'string' && mergedValue.length
      ? `${mergedValue}-${RESPONSIVE_PROP_SUFFIXES[index]}`
      : undefined
  ));
};

export const normalizeResponsiveProp = (value, normalizer, isCollapsable = true) => {
  const normalizedValue = (Array.isArray(value) ? value : [value]);

  if (!normalizedValue.length) {
    return [];
  }

  let [sm, md, lg, xl] = normalizedValue;

  if (normalizedValue.length === 1) {
    xl = sm;
    lg = sm;
    md = sm;
  } else if (normalizedValue.length === 2) {
    xl = md;
    lg = md;
    md = sm;
  } else if (normalizedValue.length === 3) {
    xl = lg;
  }

  return [
    ...new Set([
      ...[sm, md, lg, xl]
        .map((val) => normalizer(val))
        .map((val) => (Array.isArray(val) ? val : [val]).filter(Boolean))
        .reduce((acc, val, index, arr) => {
          if (
            isCollapsable
            && arr.every((v) => v.length === arr[0].length && v.every((_v, i) => _v === arr[0][i]))
          ) {
            return [
              ...acc,
              ...val,
            ];
          }

          return [
            ...acc,
            ...val.map((v) => (
              typeof v === 'string' && v.length
                ? `${v}-${RESPONSIVE_PROP_SUFFIXES[index]}`
                : undefined
            )),
          ];
        }, []),
    ]),
  ];
};
