<template>
  <div
    :class="{
      'valid': value != '' && value != undefined && !!!error,
      'invalid': !!error
    }"
    class="box-textarea"
  >
    <textarea
      :rows="rows"
      :class="{
        'bordered': bordered,
        'border-bottom': bordeBottom
      }"
      :style="`resize: ${resize}`"
      :placeholder="placeholder"
      :required="required"
      :name="name"
      :value="value"
      @input="handleChange($event)"
      @change="handleChange($event)"
      @focus="handleFocus($event)"
    />
    <transition name="error-fade">
      <div
        v-if="!!error"
        class="error"
      >
        {{ error }}
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'ATextarea',
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
  },
  props: {
    rows: Number,
    model: String,
    validate: String,
    value: String,
    required: Boolean,
    name: {
      type: String,
      default: '',
    },
    placeholder: String,
    error: {
      type: String,
      required: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    bordeBottom: {
      type: Boolean,
      default: false,
    },
    resize: {
      type: String,
      default: 'both',
    },
  },
  data() {
    return {
    };
  },
  watch: {
    value(val) {
      this.value = val;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e.target.value);
    },
    handleFocus(event) {
      this.$emit('focus', event.target);
    },
  },
};
</script>
<style lang="stylus" scoped>
  error = #ffdcea
  font_error = #ff95b8

  .box-textarea {
    position: relative;
    padding-bottom: 1.2rem;
    height: 120%;

    textarea {
      width: 100%;
      height: 100%;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
      border-radius: 0.35rem;
      border: 1px solid transparent;
      color: #141414
      padding: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.31;

      &.bordered {
        box-shadow: none;
        border: 1px solid #d0d0d0;
      }

      &.border-bottom {
        border-bottom: 1px solid #dddddd;
        border-radius: 0;
        padding: 0.75rem 0;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #999999;
        font-weight: 500;
        font-style: italic;
        opacity: 0.7;
      }
    }

    &.valid {
      .verified {
        visibility: visible;
      }
    }

    &.invalid {
      .bordered {
        background: error;
      }

      .border-bottom {
        border-bottom-color: error;
      }
    }

    .error {
      position: absolute;
      color: font_error;
      font-weight: 600;
      font-size: .8rem;
      bottom: 0;
      left: 0;
    }

    .error-fade-enter-active {
      transition: all .3s ease;
    }

    .error-fade-leave-active {
      transition: all .3s;
    }

    .error-fade-enter, .error-fade-leave-to {
      transform: translateY(5px);
      opacity: 0;
    }
  }
</style>
