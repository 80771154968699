<template>
  <div
    id="wedy-wedding-site"
    class="text-base leading-normal tracking-tight"
  >
    <router-view/>
    <m-notifications-container
      ignore-duplicates
    />
  </div>
</template>

<script>
import MNotificationsContainer from '@wedy/legacy-components/molecules/m-notifications-container';
import { version } from '../package.json';

export default {
  name: 'WedyWeddingSite',
  components: {
    MNotificationsContainer,
  },
  mounted() {
    // eslint-disable-next-line
    console.log(`%cwedy-wedding-site version %c${version}`, 'color:#9E67D6;', 'color:#FF536B');
  },
  metaInfo() {
    const {
      name: platformName = 'Wedy',
      publicContent: {
        about: {
          description: platformDescription,
        },
        config: {
          site_url: platformWeddingSiteUrl,
        },
        social: {
          og_image: platformImage,
        } = {},
      } = {},
    } = this.currentInstance;

    return {
      title: platformName,
      titleTemplate: (t) => [...new Set([t, platformName].map((s) => s.trim()))].join(' — '),
      meta: [
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: platformName,
        },
        platformDescription && {
          vmid: 'og:description',
          property: 'og:description',
          content: platformDescription,
        },
        platformImage && {
          vmid: 'og:image',
          property: 'og:image',
          content: platformImage,
        },
        platformWeddingSiteUrl && {
          vmid: 'og:url',
          property: 'og:url',
          content: platformWeddingSiteUrl,
        },
      ].filter(Boolean),
    };
  },
};
</script>

<style lang="stylus" scoped>
#wedy-wedding-site
  --header-height get_spacing(20)
</style>
