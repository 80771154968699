import { conformToMask } from 'text-mask-core';

export const currency = (rawValue) => {
  let number = rawValue
    .replace(/\D+/g, '')
    .replace(/^0+(0$|[^0])/, '$1');

  if (!number.length) {
    return [/\d/];
  }

  if (number === '0') {
    return ['R', '$', ' ', '0', ',', '0', '0', /[1-9]/];
  }

  number = `*${number.slice(1)}`;

  if (number.length < 3) {
    number = '#'.repeat(3 - number.length) + number;
  }

  const [, integer, fraction] = number.match(/^([\d|*|#]+)([\d|*|#]{2})$/);

  // https://www.safaribooksonline.com/library/view/regular-expressions-cookbook/9781449327453/ch06s12.html
  const normalizedInteger = integer.replace(/[*\d](?=(?:[\d]{3})+(?![\d]))/g, '$&.');

  return `R$ ${normalizedInteger},${fraction}`.split('').map((char) => {
    if (char === '#') {
      return '0';
    }

    if (char === '*') {
      return /[1-9]/;
    }

    if (/\d/.test(char)) {
      return /\d/;
    }

    return char;
  });
};

export const fromCurrency = (string) => (
  typeof string === 'string' && string.length
    ? Number(string.split(',').map((n) => n.replace(/\D+/g, '')).join('.'))
    : 0
);

export const toCurrency = (number, preserveCents = false, allowNegatives = true) => {
  if (typeof number !== 'number') {
    return '';
  }

  const { conformedValue } = conformToMask(
    `${Math.abs(number).toFixed(2)}`,
    currency,
    { guide: false },
  );

  return [
    allowNegatives && number < 0 && [/^R\$ /, 'R$ -'],
    !preserveCents && [/,00$/, ''],
  ]
    .filter(Boolean)
    .reduce((acc, [regex, replacement]) => acc.replace(regex, replacement), conformedValue);
};
