<template>
  <div :class="classes">
    <div
      v-if="hasLabel"
      class="label-container"
    >
      <span class="label">{{ label }}</span>
      <span class="dot">.</span>
      <span class="dot">.</span>
      <span class="dot">.</span>
    </div>
    <div :class="classesHearts">
      <hearts-svg class="hearts"/>
    </div>
    <div
      v-if="tips"
      class="tips-container"
    >
      <div class="tips-title">
        Você sabia?
      </div>
      <div
        class="tips"
        v-html="tip"
      />
    </div>
  </div>
</template>

<script>
import HeartsSvg from '@wedy/legacy-assets/images/icons/hearts.svg';

const TIPS = [
  'A Wedy é apoiada pela Elo7. O maior marketplace de produtos para casamentos do Brasil!',
  'Criar uma <strong>#hashtag</strong> para o seu casamento<br>é a forma mais fácil de acompanhar<br>o seu Grande Dia nas redes sociais',
  'Na cultura italiana, casar em dia de chuva<br>é um sinal de <strong>sorte e felicidade</strong>.',
  'Ao invés do vestido branco,<br>as noivas indianas utilizam <strong>a cor vermelha</strong><br>em sinal de prosperidade.',
  'Tradicionalmente na China,<br>ao invés de presentes os convidados<br><strong>dão dinheiro vivo aos noivos e noivas</strong>.',
  'Em 2018, o Brasil registrou<br>mais de <strong>1 milhão de casamentos</strong>.',
  'O número de casamentos homoafetivos cresceram 61,7% entre 2017 para 2018.',
  '<strong>84% dos clientes</strong><br>avaliam a empresa e o produto como excelente.',
  'Só na Wedy o seu site de casamento fica ativo por tempo indeterminado! 💜',
];

export default {
  name: 'MLoading',
  components: {
    HeartsSvg,
  },
  props: {
    background: {
      type: String,
      default: 'main',
      validator: (value) => [
        'alpha-black',
        'alpha-white',
        'main',
        'transparent',
      ].includes(value),
    },
    heartsBackground: {
      type: String,
      default: 'transparent',
      validator: (value) => [
        'main',
        'transparent',
      ].includes(value),
    },
    label: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'full',
      validator: (value) => [
        'contained',
        'full',
        'inline',
      ].includes(value),
    },
    tips: {
      type: [Array, Boolean],
      default: false,
      validator: (value) => (
        typeof value === 'boolean' || (
          Array.isArray(value)
          && value.length
          && value.every((v) => (
            typeof v === 'string'
            && v.length
          ))
        )
      ),
    },
  },
  data() {
    return {
      availableTips: [],
      tip: '',
    };
  },
  computed: {
    classes() {
      return [
        'loading',
        `mode-${this.mode}`,
        `background-${this.background}`,
      ];
    },
    classesHearts() {
      return [
        'hearts-container',
        `background-${this.heartsBackground}`,
      ];
    },
    hasLabel() {
      return Boolean(this.label.length);
    },
    hasTips() {
      return Boolean(this.tips);
    },
  },
  created() {
    if (this.hasTips) {
      this.updateTip();

      this._interval = setInterval(() => {
        this.updateTip();
      }, 5000);
    }
  },
  beforeDestroy() {
    if (this.hasTips && this._interval) {
      clearInterval(this._interval);
      this._interval = undefined;
    }
  },
  methods: {
    updateTip() {
      const platformInstanteceTips = TIPS;
      if (!this.availableTips.length) {
        const availableTips = [
          ...(Array.isArray(this.tips) ? this.tips : platformInstanteceTips),
        ].filter((tip) => tip !== this.tip);

        this.availableTips = availableTips;
      }

      const index = Math.floor(Math.random() * this.availableTips.length);
      const [tip] = this.availableTips.splice(index, 1);

      this.tip = tip;
    },
  },
};
</script>

<style lang="stylus" scoped>
.loading
  display flex
  flex-direction column
  justify-content center
  align-items center
  overflow hidden
  text-align center

  &.background-alpha-black
    background-color rgba(0, 0, 0, 0.4)
    color white

  &.background-alpha-white
    background-color rgba(255, 255, 255, 0.85)
    color #141414

  &.background-main
    background var(--main-color)
    color white

  &.background-transparent
    color #141414

  &.mode-contained
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    z-index 10

  &.mode-full
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    z-index 2147483647

  &.mode-inline
    width 100%

  .label-container
    margin-bottom 1.5rem

    .label
      font-weight 600

    .dot
      font-size 1.25rem
      font-weight 600
      animation blink 1.4s infinite

      &:nth-child(3)
        animation-delay .2s

      &:nth-child(4)
        animation-delay .4s

  .hearts-container
    width 100px
    height 100px
    display flex
    justify-content center
    align-items center
    border-radius 50%

    &.background-main
      background var(--main-color)

    &.background-transparent
      background transparent

    .hearts
      display block
      width 80px
      height auto

      .hearts_svg__ld.hearts_svg__ld-heartbeat
        animation heartbeat 0.8s infinite cubic-bezier(0.215, 0.61, 0.355, 1)

  .tips-container
    margin-top 1.5rem

    .tips-title
      font-weight 600

    .tips
      margin-top 0.5rem
      opacity 0.8
      line-height 1.15rem

@keyframes blink
  0%
    opacity .2

  20%
    opacity 1

  100%
    opacity .2

@keyframes heartbeat
  0%
    transform scale(1.05)

  5%
    transform scale(1.25)

  39%
    transform scale(0.9)

  45%
    transform scale(1.15)

  60%
    transform scale(1.05)

  100%
    transform scale(1)
</style>
