<template>
  <svg :style="svgStyle">
    <text
      :style="textStyle"
      x="50%"
      y="50%"
      text-anchor="middle"
      dominant-baseline="central"
    >
      {{ avatarLetter }}
    </text>
  </svg>
</template>
<script>
export default {
  name: 'AAvatarName',
  props: {
    name: { type: String, required: true },
    size: { type: String, default: '25' },
    rounded: { type: Boolean, default: false },
  },
  computed: {
    avatarLetter() {
      let nameSplit = [];
      nameSplit = this.name.toUpperCase().trim().split(' ');
      const validNames = nameSplit.filter((value) => value.length > 1);
      if (validNames.length === 1) {
        const [firstName] = validNames;
        return firstName.charAt(0) + firstName.charAt(1);
      } if (validNames.length <= 0) {
        const [uniqueName] = nameSplit;
        return uniqueName.charAt(0);
      }
      const [firstName, lastName] = validNames;
      return firstName.charAt(0) + lastName.charAt(0);
    },
    svgStyle() {
      // use hsl to define the background color.
      const letterIndex = this.avatarLetter.charCodeAt() - 64;
      const hue = (360 / 26) * letterIndex;
      const backgroundColor = `hsl(${hue}, 68%, 48%)`;
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? '100%' : '0',
        background: backgroundColor,
      };
    },
    textStyle() {
      return {
        fill: 'rgba(255, 255, 255, .7)',
        fontSize: `${this.size * 0.45}px`,
        fontWeight: 700,
      };
    },
  },
};
</script>
