import slugify from 'slugify';

slugify.extend({ $: '' });
slugify.extend({ '%': '' });
slugify.extend({ '&': '' });
slugify.extend({ '<': '' });
slugify.extend({ '>': '' });
slugify.extend({ '|': '' });
slugify.extend({ '¢': '' });
slugify.extend({ '£': '' });
slugify.extend({ '¤': '' });
slugify.extend({ '¥': '' });
slugify.extend({ '©': '(c)' });
slugify.extend({ '®': '(r)' });
slugify.extend({ '‘': '' });
slugify.extend({ '’': '' });
slugify.extend({ '“': '' });
slugify.extend({ '”': '' });
slugify.extend({ '†': '' });
slugify.extend({ '•': '' });
slugify.extend({ '…': '' });
slugify.extend({ '₠': '' });
slugify.extend({ '₢': '' });
slugify.extend({ '₣': '' });
slugify.extend({ '₤': '' });
slugify.extend({ '₥': '' });
slugify.extend({ '₦': '' });
slugify.extend({ '₧': '' });
slugify.extend({ '₨': '' });
slugify.extend({ '₩': '' });
slugify.extend({ '₪': '' });
slugify.extend({ '₫': '' });
slugify.extend({ '€': '' });
slugify.extend({ '₭': '' });
slugify.extend({ '₮': '' });
slugify.extend({ '₯': '' });
slugify.extend({ '₰': '' });
slugify.extend({ '₱': '' });
slugify.extend({ '₲': '' });
slugify.extend({ '₳': '' });
slugify.extend({ '₴': '' });
slugify.extend({ '₵': '' });
slugify.extend({ '₹': '' });
slugify.extend({ '₽': '' });
slugify.extend({ '₿': '' });
slugify.extend({ '℠': 'sm' });
slugify.extend({ '™': 'tm' });
slugify.extend({ '∂': '' });
slugify.extend({ '∆': '' });
slugify.extend({ '∑': '' });
slugify.extend({ '∞': '' });
slugify.extend({ '♥': '' });

slugify.extend({ '\\': ' ' });
slugify.extend({ '/': ' ' });
slugify.extend({ ',': ' ' });
slugify.extend({ '.': ' ' });
slugify.extend({ _: ' ' });

export default (value, isSoft = false) => slugify(value.replace(/R\$/g, ''))
  .toLowerCase()
  .replace(/[^a-z0-9-]/g, '')
  .concat(isSoft && /\s$/.test(value) ? '-' : '');
