const EXPIRATION_DAYS = 90;
const COOKIE_PREFIX = 'attr';
const COOKIE_TRIGGER = 'attribution';
const COOKIE_DOMAIN = '.wedy.com';

const expiration = new Date();
expiration.setDate(expiration.getDate() + EXPIRATION_DAYS);

const setCookie = (name, value) => {
  document.cookie = `${COOKIE_PREFIX}_${name}=${value}; expires=${expiration.toUTCString()}; domain=${COOKIE_DOMAIN}; path=/`;
};

const hasCookie = (name) => document.cookie.indexOf(`${name}=`) >= 0;

const getUTMValues = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return ['utm_source', 'utm_medium', 'utm_campaign']
    .map((utm) => ({ [utm]: searchParams.get(utm) }))
    .reduce((object, item) => ({ ...object, ...item }));
};

const setAttributionCookies = () => {
  if (hasCookie(COOKIE_TRIGGER)) return;
  const cookiesValues = {
    [COOKIE_TRIGGER]: 'true',
    landing_page: window.location.href,
    referrer: document.referrer,
    first_visit_date: new Date().toISOString(),
    ...getUTMValues(),
  };
  Object.keys(cookiesValues).forEach((name) => {
    setCookie(name, cookiesValues[name]);
  });
};

export default function (to, from, next) {
  setAttributionCookies();
  next();
}
