<template>
  <core-box
    direction="row"
    class="system-notification-help"
    justify-content="center"
    align-items="center"
    padding="2 3"
  >
    <core-box
      margin="0 3 0 0"
    >
      <core-text
        size="small"
        type="body"
      >
        {{ text }}
      </core-text>
    </core-box>
    <core-box>
      <a-button
        size="small"
        @click="$support.open()"
      >
        Preciso de ajuda
      </a-button>
    </core-box>
  </core-box>
</template>

<script>
import CoreBox from '../core/core-box';
import CoreText from '../core/core-text';
import AButton from './a-button.vue';

export default {
  name: 'ANotificationHelp',
  components: {
    CoreBox,
    CoreText,
    AButton,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="stylus">
.system-notification-help
  border 0.5px solid var(--grey-light)
  background-color var(--light-color)
  box-shadow 0px 2px 30px rgba(0, 0, 0, 0.05)
  border-radius 5px
</style>
