import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

import router from '../router';

export default function () {
  Vue.use(VueAnalytics, {
    id: 'G-6T3RM3K5Z6',
    router,
  });
}
