export default function ({ publicContent: { appearance } }) {
  const { style } = document.documentElement;

  style.setProperty('--main-color', appearance.colors.primary_color);
  style.setProperty('--main-color-cta', appearance.colors.main_color_cta);
  style.setProperty('--main-lighten-color', appearance.colors.main_lighten_color);
  style.setProperty('--main-darken-color', appearance.colors.main_darken_color);
  style.setProperty('--light-gradient-color', appearance.colors.light_gradient_color);
  style.setProperty('--dark-gradient-color', appearance.colors.dark_gradient_color);

  document.getElementById('favicon').setAttribute('href', `${window.appHostDomain}/favicon/128x128`);
  document.getElementById('favicon-96x96').setAttribute('href', `${window.appHostDomain}/favicon/96x96`);
  document.getElementById('favicon-128x128').setAttribute('href', `${window.appHostDomain}/favicon/128x128`);
  document.getElementById('favicon-144x144').setAttribute('href', `${window.appHostDomain}/favicon/144x144`);
  document.getElementById('favicon-152x152').setAttribute('href', `${window.appHostDomain}/favicon/152x152`);
  document.getElementById('favicon-192x192').setAttribute('href', `${window.appHostDomain}/favicon/192x192`);
  document.getElementById('favicon-512x512').setAttribute('href', `${window.appHostDomain}/favicon/512x512`);
}
