export const IS_PRODUCTION = process.env.NODE_ENV !== 'development';

const IP_REGEX = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const APP_URL = [
  window.location.protocol,
  [
    process.env.VUE_APP_APP_URL_SUBDOMAIN,
    [
      IP_REGEX.test(window.location.hostname)
        ? window.location.hostname
        : window.location.hostname.split('.').splice(-2).join('.'),
      process.env.VUE_APP_APP_URL_PORT,
    ].filter(Boolean).join(':'),
  ].filter(Boolean).join('.'),
].filter(Boolean).join('//');

export const WEDDING_SITE_URL = process.env.VUE_APP_WEDDING_SITE_URL_OVERWRITE || [
  window.location.protocol,
  [
    process.env.VUE_APP_WEDDING_SITE_URL_SUBDOMAIN,
    [
      IP_REGEX.test(window.location.hostname)
        ? window.location.hostname
        : window.location.hostname.split('.').splice(-2).join('.'),
      process.env.VUE_APP_WEDDING_SITE_URL_PORT,
    ].filter(Boolean).join(':'),
  ].filter(Boolean).join('.'),
].filter(Boolean).join('//');

export const EXPERIMENT_CHECKOUT_CARD_NAME = {
  experiment: 'checkout-card-name',
  alternatives: ['original', 'alternative'],
};

export const PLATFORM_INSTANCE = {
  name: 'Wedy',
  publicContent: {
    config: {
      type: 'full',
      plans: true,
      domain: 'wedy.com',
      gift_registry_configurable: true,
      site_url: 'https://casamento.wedy.com',
      app_url: 'https://app.wedy.com',
      platform_app_url: 'https://wedy.com',
      anonymous_email: 'anonymous@wedy.com',
    },
    sign_in: {
      welcome: 'A jornada do seu casamento começa agora',
    },
    sign_up: {
      welcome: {
        feature_gifts: 'Receba presentes em dinheiro',
        feature_gifts_fee: 'Menores taxas para presentes em dinheiro',
        feature_rsvp: 'RSVP em tempo real',
      },
    },
    onboarding: {
      key: 'wedy-key',
      tourEnabled: true,
      videoEnabled: true,
      helpLinksEnabled: true,
    },
    about: {
      tagline: 'Todo o amor pelo seu casamento!',
      title: 'Wedy',
      description: 'Planeje e organize o seu casamento e receba os presentes que deseja ganhar com elegância e segurança.',
    },
    apps: {
      android: {
        url: 'https://play.google.com/store/apps/details?id=com.wedy.app&rdid=com.wedy.app',
        application: {
          platform: 'play',
          id: 'com.wedy.app',
        },
        prefer_related_applications: true,
      },
    },
    customer_success: {
      email: 'Wedy <contato@wedy.com>',
      phone: '0800 777 9020',
      tools: {
        name: 'intercom',
        app_id: '70293675e8f131337a944197739ded9d884d344f',
        app_key: 'df91c80b53b0e1e0aa1b48778055f9aae4ef31b3',
      },
    },
    gateway: {
      interest_rate: 2.99,
      max_installments: 6,
      free_installments: 1,
      withdrawal_cost: 1.0,
      split_rule_rates: {
        1: 0.0,
        2: 0.85,
        3: 1.7,
        4: 2.55,
        5: 3.4,
        6: 4.25,
        7: 5.1,
        8: 5.95,
        9: 6.8,
        10: 7.65,
        11: 8.5,
        12: 9.35,
      },
    },
    marketing: {
      one_signal: {
        app_id: '0238fea7-b153-4d15-a948-d18af7ec1903',
        action_message: 'Você aceita ficar por dentro de tudo o que acontece no seu casamento?',
        welcome: {
          title: 'Wedy - Todo o amor pelo seu casamento',
          message: 'Obrigado por confiar na Wedy, aproveite! 💜',
        },
      },
      analytics: {
        id: 'UA-20290239-1',
      },
      adwords: {
        id: 'AW-983920524',
        conversions: {
          account_created: "AW-983920524/SWz9CKSxplYQjN-V1QM'",
          card_published: 'AW-983920524/-TFLCKqs_ZQBEIzfldUD',
          bank_account_registered: 'AW-983920524/-TFLCKqs_ZQBEIzfldUD',
        },
        alternative_id: 'AW-980077317',
      },
    },
    social: {
      youtube: {
        url: 'https://youtube.com/Wedybr',
      },
      whatsapp: {
        url: 'https://api.whatsapp.com/send?phone=551132300573&text=Oi%20Wedy',
      },
      facebook: {
        app_id: 364325103616518,
        url: 'https://facebook.com/wedybr',
      },
      instagram: {
        url: 'https://instagram.com/wedybr',
      },
      og_image: 'https://assets.wedy.com/assets/wedding-site/og-wedy-80542152f6d49da519e7e59bf574b453c91f879b39b1dd11a311c4f722000a22.png',
    },
    pages: {
      about: 'https://casamento.wedy.com/about.html',
      blog: 'https://blog.wedy.com/',
      credibility: 'https://casamento.wedy.com/credibility.html',
      invite: 'https://casamento.wedy.com/invite.html',
      search: 'https://casamento.wedy.com/search.html',
      contact: 'https://casamento.wedy.com/contact.html',
      terms: 'https://casamento.wedy.com/terms.html',
      privacy: 'https://casamento.wedy.com/privacity.html',
      plans: 'https://casamento.wedy.com/plans.html',
      giftlist: 'https://casamento.wedy.com/lista-de-casamento',
      help: 'http://ajuda.wedy.com',
      logout: 'https://wedy.com/sair',
    },
    appearance: {
      colors: {
        primary_color: '#822CC6',
        main_color_cta: 'rgb(68,204,146)',
        main_lighten_color: 'rgb(138,96,255)',
        main_darken_color: 'rgb(100,66,197)',
        light_gradient_color: '#AB94FF',
        dark_gradient_color: '#754DE6',
      },
      lottie_animations: {
        airplane: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/plane_orange.json',
      },
      notifications: {
        empty_giftlist: {
          message: 'Você ainda não criou a sua lista de casamento. Não deixe para depois.',
          action: '/panel/gifts_manager/start',
        },
      },
      images: {
        icons: {
          '72x72': 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/favicons/72x72.png',
          '96x96': 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/favicons/96x96.png',
          '128x128': 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/favicons/128x128.png',
          '144x144': 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/favicons/144x144.png',
          '152x152': 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/favicons/152x152.png',
          '192x192': 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/favicons/192x192.png',
          '384x384': 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/favicons/384x384.png',
          '512x512': 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/favicons/512x512.png',
        },
        logos: {
          simple: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-simple.svg',
          white: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-white.svg',
          white_horizontal: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-white-horizontal.svg',
          gray: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-gray.svg',
          gray_png: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-gray.png',
          vertical: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-vertical.svg',
          vertical_png: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-vertical.png',
          horizontal: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-horizontal.svg',
          horizontal_png: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/logo-horizontal.png',
        },
        wedding_site: {
          cards_confirm_refuse: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/greeting-cards/gift-card.gif',
          pick_cards: {
            simple: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/greeting-cards/simples.png',
            classic: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/greeting-cards/classic.png',
            premium: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/greeting-cards/premium.png',
          },
        },
        tags: {
          gift_png: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/tags/tags/gift-tag.png',
          logo_png: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/logos/tags/tags/logo-tag.png',
        },
        organizer_platform_notes: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/organizer-fi.svg',
        empty_gift: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/gift-empty.svg',
        couple_site: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/site-fi.svg',
        guests_letter: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/guests-fi.svg',
        organizer_notes: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/organizer-fi.svg',
        couple_in_love: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/couple-in-love.svg',
        timeline_footer_desktop: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/timeline-footer-desktop.svg',
        timeline_footer_mobile: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/timeline-footer-mobile.svg',
        withdraw: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/withdraw.svg',
        withdraw_card: 'https://s3-sa-east-1.amazonaws.com/wedy-assets/wedy/illustrations/withdraw_card.svg',
      },
    },
  },
};
