/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import api from './graphql';

const state = {
  wedding: {},
};

const getters = {
  wedding(state) {
    return state.wedding;
  },
};

const mutations = {
  setWedding(state, { wedding }) {
    state.wedding = wedding;
  },
};

const actions = {
  async fetchWedding({ commit }, { slug }) {
    const wedding = await api.queryWedding({ slug });
    commit('setWedding', { wedding });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
